import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useApp } from '../hooks';
import { Footer } from './Footer';
import { Header } from './Header';

export function MainContainer({ children }: { children: ReactNode }) {
  const { isMobile } = useApp();

  return (
    <Flex flex={1} direction={'column'} mb={isMobile ? '80px' : 0} height={'100vh'}>
      <Header />
      <Flex flex={1} direction={'column'} bg={'text.blue'} p={10}>
        {children}
      </Flex>
      <Footer />
    </Flex>
  );
}
