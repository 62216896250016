import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useApp, useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
//import { TicketDetails } from './TicketDetails';
import { useParams } from 'react-router-dom';
import { TicketDetails } from './TicketDetails';

export function Tickets() {
  const { isMobile, url } = useApp();
  const { spectateurData } = useFetchData();
  const { evenementId } = useParams();
  const { evenementsData } = useFetchData();
  const evenement = evenementsData.byId[evenementId];

  if (spectateurData.spectateur) {
    return (
      <MainContainer>
        <Flex direction={'column'} justifyContent={'center'} alignItems={'center'} mt={20}>
          {/* <Flex
            justifyContent={'center'}
            alignItems={'center'}
            // height={'100%'}
            maxWidth={isMobile ? '100%' : '50vw'}
            flex={1}
            color={'white'}
            bgGradient="linear(to-bl, #00143D, #000309BA )"
            borderRadius="15px"
          > */}
          <TicketDetails evenement={evenement} />
        </Flex>
        {/* </Flex> */}
      </MainContainer>
    );
  }
  return null;
}
