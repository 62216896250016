import React from 'react';
import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useFetchData } from '../../hooks';

export function LogoutButton() {
  const { spectateurData } = useFetchData();
  const handleLogoutClick = () => {
    spectateurData.logout();
  };

  return (
    <Button width="100%" onClick={handleLogoutClick} bg={'text.lightBlue2'} color={'white'}>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Text fontWeight={'medium'}>Se déconnecter</Text>
        <Image width={5} src={'/icons/logout_icon.png'} />
      </Flex>
    </Button>
  );
}
