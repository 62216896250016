import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
import { EventSection } from './EventSection';
import { fetchPopularEvenements } from '../../utils/services';
import { EvenementResponse } from '../../models/Evenement';
import './index.css';
import { LoadingComponent } from '../../shared-components';

export const Home = () => {
  useFetchData();

  // useEffect(() => {
  //   if (evenementsDataS.status.fetchPopularEvenements === 'idle') {
  //     handleLog(['Fetching popular events...']);
  //     //evenementsDataS.loadMore();
  //   }
  // }, []);

  const [evenements, setEvenements] = useState<EvenementResponse[]>([]);
  const loadPopularEvenements = async () => {
    try {
      const result = await fetchPopularEvenements({ page: 1, size: 10 });
      setEvenements(result);
      console.log(result);
    } catch (error) {
      console.error('Erreur lors du chargement des événements populaires:', error);
    }
  };
  useEffect(() => {
    loadPopularEvenements();
    console.log('setEvenements:', evenements);
  }, []);

  return (
    <MainContainer>
      {/* Events */}
      <Flex direction={'column'} justifyContent={'center'} gap={5}>
        <Text fontSize={'large'} fontWeight={'medium'} color={'text.yellow'} ml={2} mb={5}>
          EVENEMENTS POPULAIRES
        </Text>

        {evenements.length == 0 && (
          <div className="loader">
            <LoadingComponent />
          </div>
        )}
        {evenements.length > 0 && <EventSection evenements={evenements} />}
        <Flex flex={1} justifyContent={'center'} mt={10}>
          {/* {evenementsData && (
            <Button
              px={10}
              bg={'text.yellow'}
              justifySelf={'center'}
              //onClick={evenementsData.loadMore}
            >
              Voir plus
            </Button>
          )} */}
        </Flex>
      </Flex>
    </MainContainer>
  );
};
