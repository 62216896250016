import { EmailIcon, ExternalLinkIcon, QuestionIcon, RepeatIcon } from '@chakra-ui/icons';
import { Flex, Text, Image, Box, Icon, Link, Button } from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useApp, useFetchData } from '../hooks';
import { MainContainer } from '../shared-layout';
import { RootState } from '../store';

interface DeviceDetectionResult {
  isAndroid: boolean;
  isIOS: boolean;
}
export function ThankYou() {
  const { isMobile, url } = useApp();
  const basket = useSelector((state: RootState) => state.basket);
  const { evenementsData, spectateurData } = useFetchData();
  const ticketCount = basket.tickets.length > 1 ? `${basket.tickets.length} billets` : '1 billet';
  function redirectToAppStore() {
    const userAgent = navigator.userAgent;

    if (isMobile) {
      if (userAgent.match(/Android/i)) {
        // Rediriger vers le Google Play Store
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cobalte.nfticket';
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        // Rediriger vers l'App Store d'Apple
        window.location.href = 'https://apps.apple.com/fr/app/nf-ticket/id6463010132';
      }
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.cobalte.nfticket';
    }
  }
  if (!spectateurData.spectateur) {
    url.navigate('/login');
  }
  if (spectateurData.spectateur && basket.evenementId) {
    return (
      <MainContainer>
        <Flex
          direction={'column'}
          maxWidth={isMobile ? '100%' : '80vw'}
          color={'white'}
          borderColor={'text.lightBlu2'}
          borderWidth={2}
          p={5}
          mb={20}
          fontWeight={'medium'}
          justifyContent={'center'}
          gap={5}
        >
          <Text fontSize={'x-large'} fontWeight={'medium'}>
            C&apos;est dans la poche! Tu as {ticketCount},{' '}
            {spectateurData.spectateur.nom.stringValue}{' '}
            {spectateurData.spectateur.prenom.stringValue} :)
          </Text>

          <Flex
            direction={isMobile ? 'column' : 'row'}
            bg={'text.lightBlue'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={5}
            px={5}
            py={10}
          >
            <Flex width={'100%'} direction={'column'} fontSize={'md'} gap={5}>
              <Text>👉 Scanner ce QRcode pour télécharger l&apos;application NF-Ticket!</Text>
              <Text>Si elle n&apos;est pas encore installée voici le lien de téléchargement:</Text>
              <Flex alignItems="center" gap={2}>
                <Button style={{ width: '60px', height: '40px' }} colorScheme="gray">
                  <Link
                    href={
                      'https://play.google.com/store/apps/details?id=com.cobalte.nfticket&pli=1'
                    }
                    target="_blank"
                  >
                    <Image
                      src="/images/jeu-de-google.png"
                      alt="Télécharger l'application sur Android"
                      width={5}
                    />
                  </Link>
                </Button>
                {/* Ajouter un autre bouton ici */}
                <Button style={{ width: '60px', height: '40px' }} colorScheme="gray">
                  <Link
                    href={'https://apps.apple.com/fr/app/nf-ticket/id6463010132?platform=iphone'}
                    target="_blank"
                  >
                    <Image
                      src="/icons/social/iOS.svg"
                      alt="Télécharger l'application sur Apple"
                      width={10}
                    />
                  </Link>
                </Button>
              </Flex>
            </Flex>

            <QRCode value="https://play.google.com/store/apps/details?id=com.cobalte.nfticket&pli=1" />
          </Flex>

          <Flex
            bg={'text.lightBlue'}
            justifyContent={'space-between'}
            alignItems={'left'}
            gap={5}
            py={10}
            px={5}
            direction={'column'}
          >
            <Text>
              Pourquoi tu ne peux pas recevoir ton billet par email et qu&apos;il faut absolument
              l&apos;application?
            </Text>
            <Text color={'text.lightBlue2'}>
              Nous sommes une billetterie NFT, qui permet d&apos;éviter les arnaques et
              d&apos;améliorer ton expérience avant, pendant et après ton évènement. Chez NF-Ticket,
              un billet ce n&apos;est pas qu&apos;un accès, c&apos;est bien plus! :)
            </Text>
            <Text color={'text.lightBlue2'}>
              Donc télécharge la{' '}
              <Link href="#" onClick={redirectToAppStore}>
                <Text as={'u'}>ici</Text>
              </Link>
              , et renseigne les mêmes identifiants utilisés pour la commande.
            </Text>
          </Flex>

          <Text fontSize={'x-large'} fontWeight={'medium'} mt={20}>
            {evenementsData.byId[basket.evenementId].nomEvenement.stringValue}
          </Text>

          <Flex>
            <Image
              width={'100%'}
              src={evenementsData.byId[basket.evenementId].banniereImg.stringValue}
              alt={evenementsData.byId[basket.evenementId].banniereImg.stringValue}
              rounded={'12px'}
            />
          </Flex>

          <Box borderBottom="1px solid white" my={2} />

          <Flex
            direction={'column'}
            width={isMobile ? '100%' : '60vw'}
            color={'white'}
            fontWeight={'medium'}
            justifyContent={'center'}
            gap={1}
          >
            <Flex alignItems={'center'}>
              <Icon as={FaMapMarkerAlt} boxSize={6} m={2} />
              <Link href="#" onClick={redirectToAppStore}>
                <Flex as={'u'}>
                  Retrouve ton billet sur l&apos;application NF-Ticket
                  <Icon as={ExternalLinkIcon} boxSize={4} m={1} />
                </Flex>
              </Link>
            </Flex>

            <Flex alignItems={'center'}>
              <Icon as={EmailIcon} boxSize={6} m={2} />
              <Text>
                Le reçu t&apos;as été envoyé par mail à{' '}
                {spectateurData.spectateur.email.stringValue}
              </Text>
            </Flex>

            <Flex alignItems={'center'}>
              <Icon as={QuestionIcon} boxSize={6} m={2} />
              <Text>Besoin d&apos;aide? Visite notre FAQ ou contacte-nous</Text>
            </Flex>

            <Flex alignItems={'center'}>
              <Icon as={RepeatIcon} boxSize={6} m={2} />
              <Text>Retour à la page de l&apos;évènement</Text>
            </Flex>
          </Flex>
        </Flex>
      </MainContainer>
    );
  }
  return null;
}
