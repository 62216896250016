import { CategorieTicketModel } from '../models';

export const frenchMonths = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

export function formatEventDate(date: Date) {
  const dateDate = new Date(date);
  const weekday = frenchDays[dateDate.getDay()];
  const monthDay = dateDate.getDate();
  const month = frenchMonths[dateDate.getMonth()];
  return `${weekday} ${monthDay} ${month}`;
}

export function formatEventTime(date: Date) {
  const dateDate = new Date(date);
  const hour = dateDate.getHours();
  const minutes = dateDate.getMinutes();
  return minutes < 10 ? `${hour}h0${minutes}` : `${hour}h${minutes}`;
}

export function sortedAvailableTicketa(tickets: CategorieTicketModel[]) {
  if (tickets.length === 1) {
    // S'il y a une seule catégorie, retournez son prix directement
    return tickets;
  } else {
    // S'il y a plusieurs catégories, triez-les par prix
    return tickets.sort((a, b) => {
      if (a.prix && b.prix) {
        return a.prix - b.prix;
      }
      return 0;
    });
  }
}
export function sortedAvailableTicket(tickets: CategorieTicketModel[]) {
  if (tickets.length === 1) {
    // If there is only one category, return its price directly
    return tickets.slice(); // Return a copy of the array
  } else {
    // If there are multiple categories, sort them by price
    const sortedTickets = tickets.slice(); // Create a copy of the array
    return sortedTickets.sort((a, b) => (a.prix ?? 0) - (b.prix ?? 0));
  }
}
// export function getPrice(ticket: CategorieTicketModel | undefined) {
//   if (ticket && ticket.prix) {
//     if ('doubleValue' in ticket.prix) {
//       return parseFloat(parseFloat(String(ticket.prix.doubleValue)).toFixed(2));
//     } else if ('integerValue' in ticket.prix) {
//       return parseFloat(parseFloat(String(ticket.prix.integerValue)).toFixed(2));
//     } else {
//       return 0.0;
//     }
//   } else {
//     return 0.0;
//   }
// }

/*
 * Example current basket tickets: [ticket1, ticket2, ticket1, ticket2, ticket2]
 * If we want the 2nd ticket2, we need to keep count of the ticket2 we come across, with localTicketCounter
 */
export function findRightTicketInBasket<T>(
  tickets: T[],
  predicate: (value: T, index: number, array: T[]) => boolean,
  thisTicketNumberOccurenceInBasket = 1
): [T, number] | [undefined, undefined] {
  let localTicketCounter = 0;
  for (let i = 0; i < tickets.length; i++) {
    if (predicate(tickets[i], i, tickets)) {
      localTicketCounter++;
      if (localTicketCounter === thisTicketNumberOccurenceInBasket) {
        return [tickets[i], i];
      }
    }
  }
  return [undefined, undefined];
}

// eslint-disable-next-line
export const handleLog = (...args: any[]) => {
  if (process.env.REACT_APP_NODE_ENV !== 'production') {
    console.log(...args);
  }
};
