import React, { useEffect, useState } from 'react';
import { Flex, Box, Text, VStack } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { useApp, useFetchData } from '../../hooks';
import { EventBox } from './EventBox';
import { RootState, useAppDispatch } from '../../store';
// import { fetchSpectateurData } from '../../slices';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export function UserTabs() {
  const [selectedTab, setSelectedTab] = useState('Avenir');
  const { evenementsData, spectateurData } = useFetchData();

  const { id } = useParams();
  const { isMobile, url } = useApp();
  const dispatch = useAppDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const tokenId = authState.user?.idToken;
  // useEffect(() => {
  //   console.log('Update spectateur data ...');
  //   dispatch(fetchSpectateurData({ spectateurId: spectateurData.spectateur.id, tokenId: tokenId }));
  // }, []);

  const ticketsBought = spectateurData?.spectateur?.evenements?.arrayValue.values.map(
    (v) => v.stringValue
  );

  useEffect(() => {
    if (ticketsBought) {
      ticketsBought.forEach((ticketId) => evenementsData.fetchById(ticketId));
    }
  }, []);
  const handleRedirect = (eventId) => {
    url.navigate(`/tickets/${eventId}`);
  };
  // const values = spectateurData.spectateur.portefeuille?.arrayValue?.values;
  // const countOccurrences = (values, ticketsBought, evenementsData) => {
  //   // Check if values is an array before using map
  //   if (Array.isArray(values)) {
  //     const documentIds = values.map((value) => {
  //       // Check if value and value.referenceValue exist before accessing split
  //       if (value && value.referenceValue) {
  //         const parts = value.referenceValue.split('/');
  //         return parts[parts.length - 3];
  //       } else {
  //         console.error('Invalid value structure:', value);
  //         return null; // or handle the case where value is not as expected
  //       }
  //     });

  //     const updatedEventCounts = {};

  //     // Iterate through the array and count occurrences
  //     documentIds.forEach((eventId) => {
  //       updatedEventCounts[eventId] = (updatedEventCounts[eventId] || 0) + 1;
  //     });

  //     //console.log('Event ID Occurrences:', updatedEventCounts);

  //     if (ticketsBought) {
  //       ticketsBought.forEach((evenementId) => {
  //         const eventCount = updatedEventCounts[evenementId] || 0;

  //         // Check if the event ID has occurrences and other conditions
  //         if (
  //           evenementsData.byId[evenementId] &&
  //           new Date(evenementsData.byId[evenementId].dateFin) > new Date() &&
  //           eventCount > 0
  //         ) {
  //           // Display your component here
  //           // console.log(`Event ${evenementId} has ${eventCount} occurrences`);
  //         }
  //       });

  //       // Return the updated event counts
  //       return updatedEventCounts;
  //     }
  //   } else {
  //     console.error('Values is not an array:', values);
  //   }

  //   return {};
  // };

  // const eventCounts = countOccurrences(values, ticketsBought, evenementsData);

  // const values = spectateurData.spectateur.portefeuille?.arrayValue?.values;

  // // Check if values is an array before using map
  // if (Array.isArray(values)) {
  //   const documentIds = values.map((value) => {
  //     // Check if value and value.referenceValue exist before accessing split
  //     if (value && value.referenceValue) {
  //       const parts = value.referenceValue.split('/');
  //       return parts[parts.length - 3];
  //     } else {
  //       console.error('Invalid value structure:', value);
  //       return null; // or handle the case where value is not as expected
  //     }
  //   });

  //   const eventIdCounts = {};
  //   const updatedEventCounts = { eventCounts };
  //   // Iterate through the array and count occurrences
  //   documentIds.forEach((eventId) => {
  //     // Use eventId as a key in the object
  //     //eventIdCounts[eventId] = (eventIdCounts[eventId] || 0) + 1;
  //     updatedEventCounts[eventId] = (updatedEventCounts[eventId] || 0) + 1;
  //   });
  //   console.log('Event ID Occurrences:', eventIdCounts);
  //   if (ticketsBought) {
  //     ticketsBought.forEach((evenementId, index) => {
  //       // const eventCount = eventIdCounts[evenementId] || 0;
  //       const eventCount = updatedEventCounts[evenementId] || 0;
  //       // Check if the event ID has occurrences and other conditions
  //       if (
  //         evenementsData.byId[evenementId] &&
  //         new Date(evenementsData.byId[evenementId].dateFin) > new Date() &&
  //         eventCount > 0
  //       ) {
  //         // Display your component here
  //         console.log(`Event ${evenementId} has ${eventCount} occurrences`);
  //       }
  //     });
  //   }
  // } else {
  //   console.error('Values is not an array:', values);
  // }

  // useEffect(() => {
  //   if (ticketsBought) {
  //     console.log(ticketsBought.forEach((ticketId) => fetchListeTickets(ticketId, tokenId)));
  //     ticketsBought.forEach((ticketId) => fetchListeTickets(ticketId, tokenId));
  //   }
  // }, []);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  if (!evenementsData) {
    return null;
  }

  return (
    <Flex direction="column" width={'100%'}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Box
            px={4}
            py={2}
            cursor="pointer"
            borderBottom={selectedTab === 'Avenir' ? '2px solid' : ''}
            borderColor={'text.lightBlue2'}
            onClick={() => handleTabClick('Avenir')}
          >
            <Text fontSize={isMobile ? '0.9rem' : '1rem'}>À venir</Text>
          </Box>
          <Box
            px={4}
            py={2}
            cursor="pointer"
            borderBottom={selectedTab === 'Passés' ? '2px solid' : ''}
            borderColor={'text.lightBlue2'}
            onClick={() => handleTabClick('Passés')}
          >
            <Text fontSize={isMobile ? '0.9rem' : '1rem'}>Passés</Text>
          </Box>
        </Flex>
        <SettingsIcon boxSize={6} /> {/* TODO Settings */}
      </Flex>

      {ticketsBought && (
        <>
          {selectedTab === 'Avenir' && (
            <VStack align="start" spacing={2}>
              {ticketsBought.map((evenementId, index) => {
                if (
                  evenementsData.byId[evenementId] &&
                  evenementsData.byId[evenementId].etat &&
                  evenementsData.byId[evenementId].etat.toString() === 'publié'
                ) {
                  return (
                    <Box key={index} style={{ width: '100%', cursor: 'pointer' }}>
                      <EventBox evenement={evenementsData.byId[evenementId]} />
                      <Box borderBottom="2px solid white" my={2} />
                    </Box>
                  );
                }
                return null;
              })}
            </VStack>
          )}

          {selectedTab === 'Passés' && (
            <VStack align="start" spacing={2}>
              {ticketsBought.map((evenementId, index) => {
                if (
                  evenementsData.byId[evenementId] &&
                  evenementsData.byId[evenementId].etat &&
                  evenementsData.byId[evenementId].etat.toString() === 'passé'
                ) {
                  return (
                    <Box key={index} style={{ width: '100%', cursor: 'pointer' }}>
                      <EventBox evenement={evenementsData.byId[evenementId]} />
                      <Box borderBottom="2px solid white" my={2} />
                    </Box>
                  );
                }
                return null;
              })}
            </VStack>
          )}
        </>
      )}
    </Flex>
  );
}
