import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  Image,
  HStack,

  // HStack,
  // TabList,
  // Tabs,
  // Tab,
  //   TabList,
  //   Tabs,
  //   Tab,
  //   TabPanels,
  //   TabPanel,
} from '@chakra-ui/react';
import { useApp, useFetchData } from '../../hooks';

import { EvenementResponse } from '../../models';
import { Flex } from '@chakra-ui/react';
// import { LoadingComponent } from '../../shared-components';
import LazyLoad from 'react-lazyload';
// import { formatEventDate } from '../../utils';

import 'swiper/css/bundle';
import 'swiper/element/css/scrollbar';
import { formatEventDate } from '../../utils';
import QRCode from 'qrcode.react';

export function TicketDetails({ evenement }: { evenement: EvenementResponse }) {
  const swiperElRef = useRef(null);
  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener('swiperprogress', (e) => {
      const [swiper, progress] = e.detail;
      setActiveTab(swiper.realIndex);
    });

  }, []);

  const { spectateurData } = useFetchData();
  const { isMobile } = useApp();

  const values = spectateurData.spectateur.portefeuille?.arrayValue?.values;
  const countOccurrencesForEvent = (eventId, values) => {
    if (Array.isArray(values)) {
      const documentIds = values.map((value) => {
        if (value && value.referenceValue) {
          const parts = value.referenceValue.split('/');
          return parts[parts.length - 3];
        } else {
          console.error('Invalid value structure:', value);
          return null;
        }
      });

      // Count occurrences for the specific event
      const eventCount = documentIds.reduce((count, id) => {
        return id === eventId ? count + 1 : count;
      }, 0);

      return eventCount;
    }
  };
  // const displayCategoriesForEvent = (eventId1, values) => {
  //   if (Array.isArray(values)) {
  //     // Vérifiez si l'ID de l'événement a été trouvé dans le chemin
  //     if (eventId1) {
  //       if (Array.isArray(values)) {
  //         const categories = values.reduce((acc, value) => {
  //           // Vérifiez si value et value.referenceValue existent avant d'accéder à split
  //           if (value && value.referenceValue) {
  //             const parts = value.referenceValue.split('/');
  //             const documentId = parts[parts.length - 3];

  //             // Si l'ID du document correspond à l'ID de l'événement, ajoutez la catégorie à l'accumulateur
  //             if (documentId === eventId) {
  //               const category = parts[parts.length - 1];
  //               acc.push(category);
  //             }
  //           }
  //           return acc;
  //         }, []);

  //         // Vérifiez si des catégories ont été trouvées
  //         if (categories.length > 0) {
  //           console.log(`Catégories pour l'événement ${eventId1}:`);
  //           categories.forEach((category) => {
  //             console.log(category);
  //           });
  //         } else {
  //           console.log(`Aucune catégorie trouvée pour l'événement ${eventId1}.`);
  //         }
  //       }
  //     }
  //   }
  // };

  const eventId = evenement.id;
  const occurrencesForEvent = countOccurrencesForEvent(eventId, values);
  // const categorie = displayCategoriesForEvent(eventId, values);
  // const catId = fetchTicketsByEvenementId(eventId);
  // console.log(catId);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = Array.from({ length: occurrencesForEvent }, (_, index) => `Tab ${index + 1}`);

  // const handleTabClick = (index: number) => {
  //   setActiveTab(index);
  // };

  return (
    <Flex direction="column" mt={-24} gap={3}>
      <Text color="white" align={'center'}>
        Billet {activeTab + 1} sur {tabs.length}
      </Text>

      <Flex direction="column" justifyContent={'center'}>
        <div
          style={{
            backgroundColor: 'text.blue',
            width: '80vw',
            height: isMobile ? '500px' : '300px',
            borderRadius: '15px', // Ajustez la valeur selon vos préférences
            //    background: 'linear-gradient(to bottom left, #00143D, #000309BA)',
          }}
        >
          <swiper-container
            loop="true"
            ref={swiperElRef}
            slides-per-view="1"
            navigation="true"
            // pagination="true"
          >
            {tabs.map((_, index) => (
              <swiper-slide key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                <Flex
                  bgGradient={'linear-gradient(to bottom left, #00143D, #000309BA)'}
                  width={'60vw'}
                  justifyContent={'start'}
                  borderRadius={15}
                  direction={isMobile ? 'column' : 'row'}
                >
                  <LazyLoad height={'200px'} offset={100}>
                    <Image
                      w={'300px'}
                      onLoad={() => setImageLoaded(true)}
                      display={imageLoaded ? 'block' : 'none'}
                      height={'100%'}
                      src={evenement.banniereImg.toString()}
                      alt={evenement.nomEvenement.toString()}
                      objectFit="cover"
                      borderRadius="15px"
                    />
                  </LazyLoad>
                  <Flex
                    color={'white'}
                    flex={1} // Pour q
                    direction={'column'}
                    gap={isMobile ? 2 : 6}
                    m={7}
                  >
                    <HStack>
                      <Text fontWeight={'bold'} fontSize={isMobile ? 'sm' : 'x-large'}>
                        {evenement.nomEvenement.toString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'/icons/user_for_ticket.svg '} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {evenement.organizerName.toString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'  /icons/calender_for_ticket.svg'} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {formatEventDate(evenement.dateDebut)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'  /icons/location_for_ticket.svg'} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {evenement.adresse.adresse.toString()}
                      </Text>
                    </HStack>
                  </Flex>
                  <Flex m={isMobile ? 10 : 14}>
                    <QRCode
                      value="https://play.google.com/store/apps/details?id=com.cobalte.nfticket&pli=1"
                      style={{ boxShadow: '0px 0px 20px rgba(64, 123, 255, 0.5)' }}
                    />
                  </Flex>
                </Flex>
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
        {/* <swiper-container slides-per-view="1" css-mode="true">
          {tabs.map((_, index) => (
            <swiper-slide key={index}>
              {
                <Flex direction={'column'}>
                  <Flex
                    width={'100%'}
                    height={'200px'}
                    position="absolute"
                    alignItems="center"
                    justifyContent="center"
                    //backgroundColor="white"
                    zIndex={imageLoaded ? -1 : 1}
                  >
                    {!imageLoaded && <LoadingComponent />}
                  </Flex>
                  Image
                  <LazyLoad height={'300px'} offset={100}>
                    <Image
                      mt={9}
                      onLoad={() => setImageLoaded(true)}
                      display={imageLoaded ? 'block' : 'none'}
                      height={'100%'}
                      src={evenement.banniereImg.toString()}
                      alt={evenement.nomEvenement.toString()}
                      objectFit="cover"
                      borderRadius="15px 15px 0px  0px"
                    />
                  </LazyLoad>
                  <Flex direction={'column'} gap={isMobile ? 2 : 6} m={7}>
                    <HStack gap={6}>
                      <Text fontWeight={'bold'} fontSize={isMobile ? 'sm' : 'x-large'}>
                        {evenement.nomEvenement.toString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'/icons/user_for_ticket.svg '} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {evenement.organizerName.toString()}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'  /icons/calender_for_ticket.svg'} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {formatEventDate(evenement.dateDebut)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Image width={5} src={'  /icons/location_for_ticket.svg'} />
                      <Text fontSize={isMobile ? 'sm' : 'large'}>
                        {evenement.adresse.adresse.toString()}
                      </Text>
                    </HStack>
                  </Flex>
                </Flex>
                // </Flex>
              }
            </swiper-slide>
          ))}
        </swiper-container> */}
        {/* {/* </Tabs> */}
      </Flex>
    </Flex>
  );
}
