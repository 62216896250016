import { Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { DEFAULT_USER_IMG } from '../config';
import { useApp, useFetchData } from '../hooks';

export const ConnectButton = () => {
  const { spectateurData } = useFetchData();
  const { url, isMobile } = useApp();

  if (spectateurData.spectateur) {
    return (
      <Flex
        ml={isMobile ? -2 : 0}
        alignItems={'center'}
        gap={1}
        //_hover={{ bg: 'text.lightBlue' }}
        p={1}
        rounded={5}
        cursor="pointer"
        onClick={() => url.navigate('/user')}
      >
        <Flex
          width={'40px'}
          height={'40px'}
          bg={'white'}
          rounded={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          fontWeight={'medium'}
        >
          <Image
            width={'35px'}
            height={'35px'}
            rounded={'50%'}
            src={
              spectateurData.spectateur.photoProfil
                ? spectateurData.spectateur.photoProfil.stringValue
                : DEFAULT_USER_IMG
            }
          />
        </Flex>

        <Text
          mr={'10px'}
          ml={'5px'}
          color={isMobile ? 'text.lightBlue' : 'white'}
          fontWeight={isMobile ? 'bold' : 'normal'}
        >
          {spectateurData.spectateur.nom.stringValue}
        </Text>
      </Flex>
    );
  }
  return (
    <>
      {isMobile ? (
        <Flex alignItems={'center'} gap={1}>
          <Image width={'35px'} height={'35px'} rounded={'50%'} src={DEFAULT_USER_IMG} />
          <Link
            fontSize="sm"
            onClick={() => url.navigate('/login')}
            color={'text.lightBlue'}
            fontWeight={'bold'}
          >
            Connexion / Inscription
          </Link>
        </Flex>
      ) : (
        <Button
          bg={'text.yellow'}
          fontWeight={'small'}
          borderWidth={'1px'}
          borderColor={'text.blue'}
          color={'text.blue'}
          rounded={'10px'}
          transition={'0.3s!important'}
          boxShadow={'3px 3px 3px rgb(10, 10, 83, 0.5)'}
          p={'10px 20px'}
          _hover={{
            color: 'text.yellow',
            bg: 'text.blue',
            borderColor: 'text.yellow',
          }}
          onClick={() => url.navigate(`/login`)}
        >
          <Text fontSize={isMobile ? 'xs' : 'medium'}>Connexion / Inscription</Text>
        </Button>
      )}
    </>
  );
};
