import axios from 'axios';
import { OrganisateurModel, OrganisateursApiResponseModel } from '../../models';
import { FIRESTORE_BASE_URL, handleApiError, handleApiResponse } from '../apiService';

export const fetchOrganisateurs = async (): Promise<OrganisateurModel[] | null | undefined> => {
  const endpoint = `${FIRESTORE_BASE_URL}/ListeOrganisateurs`;

  try {
    const response = await axios.get(endpoint);
    const data = handleApiResponse<OrganisateursApiResponseModel>(response, endpoint);

    const payload = data.documents.map((doc) => {
      const fields = doc.fields;
      const id = doc.name.split('/').pop() as string;
      return { ...fields, id } as OrganisateurModel;
    });

    return payload;
  } catch (error) {
    handleApiError(error, 'fetchOrganisateurs');
  }
};
