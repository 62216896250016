import { EvenementResponse, PaginationRequest } from '../../models';
import { handleApiError } from '../apiService';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config';

const getPublishedEventsFunction = httpsCallable(functions, 'getPublishedEvents');
const getEventById = httpsCallable(functions, 'getEventById');

export const fetchPopularEvenements = async (
  paginationRequest: PaginationRequest
): Promise<EvenementResponse[]> => {
  try {
    console.log('get published data');
    const result = await getPublishedEventsFunction({
      page: paginationRequest.page,
      size: paginationRequest.size,
    });

    const data = result.data as { events: EvenementResponse[] };
    const evenements = Array.isArray(data.events) ? data.events : [];
    const allEvents = evenements.flatMap((innerArray) => innerArray);

    return allEvents;
  } catch (error) {
    handleApiError(error, 'fetchPopularEvenements');
    throw error;
  }
};

// export const fetchEvenementById = async (
//   id: string
// ): Promise<EvenementResponse | null | undefined> => {
//   const endpoint = `${FIRESTORE_BASE_URL}/ListeEvenements/${id}`;

//   try {
//     const response = await axios.get(endpoint);
//     const data = handleApiResponse<EvenementByIdApiResponseModel>(response, endpoint);

//     const event = {
//       ...data.fields,
//       id: data.name.split('/').pop() as string,
//       organizerName: data.fields.organizerName || 'Unknown Organizer',
//     };
//     console.log('name', data.name);
//     const ticketsEndpoint = `${FIRESTORE_BASE_URL}/ListeEvenements/${id}/ListeCategoriesTicket`;
//     let ticketData;
//     try {
//       const ticketResponse = await axios.get(ticketsEndpoint);
//       ticketData = handleApiResponse<CategorieTicketApiResponseModel>(
//         ticketResponse,
//         ticketsEndpoint
//       );
//     } catch (error) {
//       ticketData = {}; // or handle error accordingly
//     }

//     const tickets = ticketData.documents
//       ? ticketData.documents.map((doc) => {
//           const fields = doc.fields as CategorieTicketModel;
//           const id = doc.name.split('/').pop() as string;
//           return { ...fields, id };
//         })
//       : [];

//     return {
//       ...event,
//       etat: event.etat,
//       ticketsCategories: tickets,
//       organizerName: data.fields.organizerName,
//     };
//   } catch (error) {
//     handleApiError(error, 'fetchEvenementById');
//   }
// };

export const fetchEvenementById = async (id: string): Promise<EvenementResponse> => {
  try {
    const result = await getEventById({
      id: id,
    });
    const data = result.data as EvenementResponse;
    return data;
  } catch (error) {
    handleApiError(error, 'fetcheventbyid');
    throw error;
  }
};
