import React from 'react';
import { Flex, Image, Link, Text } from '@chakra-ui/react';
import { useApp } from '../hooks';

export function Footer() {
  const { isMobile } = useApp();

  return (
    <Flex
      direction="column"
      position="relative"
      zIndex={99}
      as="footer"
      p={10}
      bg="text.blue"
      color="text.white"
      justifyContent="center"
      gap={5}
      borderTop={'1px solid #93b3c2'}
    >
      <Flex
        direction={isMobile ? 'column' : 'row'}
        bg="text.blue"
        color="text.white"
        justifyContent="space-around"
        gap={5}
      >
        <div>
          <Image src="/images/logo.png" alt="logo NF-TICKET" maxWidth={'250px'} />
        </div>
        <Flex direction={isMobile ? 'row' : 'row'} gap={isMobile ? 10 : 40}>
          <Flex direction="column" gap={3} _hover={{ color: '#FFD09B' }}>
            <Text fontSize={'x-large'}>NF-Ticket</Text>
            <Link href="https://www.nf-ticket.com/">
              <Text color="white" _hover={{ color: '#FFD09B' }}>
                Contactez-nous
              </Text>
            </Link>
            <Link href="https://www.nf-ticket.com/questions-frequentes" target="_blank">
              <Text color="white" _hover={{ color: '#FFD09B' }}>
                Foire à questions
              </Text>
            </Link>
          </Flex>
          <Flex direction="column" gap={3} _hover={{ color: '#FFD09B' }}>
            <Text fontSize={'x-large'}>Services</Text>
            <Link href="https://www.nf-ticket.com/">
              <Text color="white" _hover={{ color: '#FFD09B' }}>
                Nos solutions
              </Text>
            </Link>
            <Link href="https://dashboard.nf-ticket.com/login">
              <Text color="white" _hover={{ color: '#FFD09B' }}>
                Organiser un événement
              </Text>
            </Link>
          </Flex>
        </Flex>
        <Flex direction="column" gap={3} _hover={{ color: '#FFD09B' }}>
          {/* <Text fontSize={'x-large'}>Newsletter</Text>
          <Text color="lightslategray" maxWidth={isMobile ? '90vw' : '250px'} textAlign={'start'}>
            Vous souhaitez recevoir des informations sur nos fonctionnalités et nos actualités ?
          </Text>
          <Input
            placeholder="Votre adresse mail"
            backgroundColor={'white'}
            color={'text.blue'}
          ></Input>
          <Button
            width={'100px'}
            height={'30px'}
            alignSelf={'end'}
            backgroundColor={'text.lightBlue'}
            _hover={{ border: '1px solid white' }}
          >
            <Text fontSize={'14px'} fontWeight={'thin'} color={'white'}>
              Je m&apos;abonne
            </Text>
          </Button> */}
        </Flex>
      </Flex>

      <Flex
        direction={isMobile ? 'column' : 'row'}
        //justifyContent="center"
        //alignItems="center"
        bg="text.blue"
        color="text.white"
        gap={5}
        mt={isMobile ? -10 : 0}
        justifyContent="space-between"
      >
        <Flex
          marginLeft={isMobile ? '0.5 ' : '16'}
          justifyContent="start"
          alignItems="start"
          gap={5}
          direction={'column'}
        >
          <Text color="#FFD09B" fontWeight={'bold'} fontSize={'20px'} mt={20}>
            {/*fontFamily={'Assistant'}*/}
            Rejoignez-nous sur:
          </Text>
          <Flex direction={'row'} gap={5}>
            <Flex
              direction={'row'}
              width={'120px'}
              height={'40px'}
              border="2px white solid"
              borderRadius="5"
            >
              <Link
                target="_blank"
                href="https://apps.apple.com/fr/app/nf-ticket/id6463010132"
                rel="noreferrer"
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Image
                  src="/icons/iconApple_.svg"
                  alt="Télécharger l'application sur iOS"
                  width={'18px'}
                />
                <Text fontSize={12} marginLeft={2}>
                  Apple store
                </Text>
              </Link>
            </Flex>
            <Flex
              direction={'row'}
              width={'120px'}
              height={'40px'}
              border="2px white solid"
              borderRadius="5"
            >
              <Link
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.cobalte.nfticket&pli=1"
                rel="noreferrer"
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Image
                  src="/icons/GooglePlay.svg"
                  alt="Télécharger l'application sur Android"
                  width={'18px'}
                />
                <Text fontSize={12} marginLeft={2}>
                  Google play
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="row" alignItems="end" gap={8} mr={isMobile ? '0.5 ' : '16'}>
          <Link target="_blank" href="https://twitter.com/NFTicketfr" rel="noreferrer">
            <Image src="/icons/social/Twitter.svg" alt="Logo Twitter" width={10} />
          </Link>
          <Link
            target="_blank"
            href="https://www.instagram.com/nf.ticket/?igshid=NzZlODBkYWE4Ng%3D%3D"
            rel="noreferrer"
          >
            <Image src="/icons/social/Instagram.svg" alt="Logo Instagram" width={10} />
          </Link>
          <Link target="_blank" href="https://fr.linkedin.com/company/nf-ticket" rel="noreferrer">
            <Image src="/icons/social/LinkedIn.svg" alt="Logo LinkedIn" width={10} />
          </Link>
          <Link
            target="_blank"
            href="https://discord.com/channels/965660419094118430/965660420050403354"
            rel="noreferrer"
          >
            <Image src="/icons/social/Discord.svg" alt="Logo Discord" width={10} />
          </Link>
        </Flex>
      </Flex>

      <Flex
        color="lightslategray"
        fontSize="0.8em"
        textAlign="center"
        alignItems={'center'}
        justifyContent={'center'}
        gap={2}
        mt={10}
        direction={isMobile ? 'column' : 'row'}
      >
        <Text>NF-Ticket by Cobalte © 2023 Tous droits réservés</Text>
        {/*<Text>{` | `}</Text>*/}
        {/* <Link href="PolitiqueDeConfidentialité.html" _hover={{ textDecoration: 'underline' }}>
          Politique de confidentialité
        </Link>
              <Text>{` | `}</Text> */}
        <Link
          target="_blank"
          href="https://www.nf-ticket.com/condtions-generales-de-vente"
          _hover={{ textDecoration: 'underline' }}
        >
          Conditions Générales de Vente
        </Link>
        {/*<Text>{` | `}</Text>
        <Link href="" _hover={{ textDecoration: 'underline' }}>
          Conditions Générales d’utilisation
            </Link> */}
      </Flex>
    </Flex>
  );
}
