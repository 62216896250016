import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoginRequestModel,
  LoginSuccessResponseModel,
  RefreshTokenSuccessResponseModel,
  RegistrationDataModel,
} from '../models';
import { apiService } from '../utils';

interface AuthState {
  user: LoginSuccessResponseModel | null;
  isLoading: boolean;
  error: string | undefined;
  tokenFetchTime: number | undefined;
}

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: undefined,
  tokenFetchTime: undefined,
};

export const loginUser = createAsyncThunk('auth/login', async (credentials: LoginRequestModel) => {
  return await apiService.login(credentials);
});

export const signupUser = createAsyncThunk(
  'auth/signup',
  async (registrationData: RegistrationDataModel) => {
    return await apiService.signup(registrationData);
  }
);

export const refreshToken = createAsyncThunk('auth/refreshToken', async (refreshToken: string) => {
  return await apiService.refreshToken(refreshToken);
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.error = undefined;
      state.tokenFetchTime = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        loginUser.fulfilled,
        (state, action: PayloadAction<LoginSuccessResponseModel | null | undefined>) => {
          state.isLoading = false;
          if (action.payload) {
            state.user = action.payload;
            state.tokenFetchTime = Date.now();
          } else {
            state.error = 'No data received';
          }
        }
      )
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(signupUser.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        signupUser.fulfilled,
        (state, action: PayloadAction<LoginSuccessResponseModel | null | undefined>) => {
          state.isLoading = false;
          if (action.payload) {
            state.user = action.payload;
            state.tokenFetchTime = Date.now();
          }
        }
      )
      .addCase(signupUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(
        refreshToken.fulfilled,
        (state, action: PayloadAction<RefreshTokenSuccessResponseModel | null | undefined>) => {
          if (action.payload && state.user) {
            state.user.idToken = action.payload.id_token;
            state.tokenFetchTime = Date.now();
          }
        }
      )
      .addCase(refreshToken.rejected, (state, action) => {
        state.user = null;
        state.error = undefined;
        state.tokenFetchTime = undefined;
        state.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
