import { useBreakpointValue } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useApp = () => {
  const isMobile = useBreakpointValue({ base: 'mobile', lg: 'desktop' }) === 'mobile';
  const location = useLocation();
  const navigate = useNavigate();

  const url = {
    location: location,
    navigate: navigate,
  };

  return { isMobile, url };
};
