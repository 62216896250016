/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { auth, realtimeDb } from '../config';

import { get, ref } from 'firebase/database';
import { deletePanier } from './services';
// import { useSelector } from 'react-redux';
// import { RootState } from '../store';
// import { useFetchData } from '../hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withDeletePanier = (Component: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = (props: any) => {
    const location = useLocation();

    const userUID = auth.currentUser?.uid;
    const stocksRef = userUID ? ref(realtimeDb, `Paniers/${userUID}`) : null;

    console.log('HOC');
    useEffect(() => {
      const handleDeletePanier = async () => {
        try {
          // Get data from the Panier node if the user is connected
          if (userUID && stocksRef) {
            const snapshot = await get(stocksRef);

            console.log('Snapshot exists:', snapshot.exists());
            if (snapshot.exists()) {
              // Panier node exists, perform deletePanier logic
              console.log('Panier deleted for evenement ID:');
              await deletePanier({
                cartId: userUID,
              });
            } else {
              // Panier node doesn't exist
              console.log('Panier node does not exist');
            }
          }
        } catch (error) {
          // Log error details for debugging
          console.error('Error fetching/deleting data from Panier:', error);
        }
      };

      handleDeletePanier();

      return () => {
        // Cleanup code if needed
      };
    }, [location.pathname, userUID, stocksRef]);

    // eslint-disable-next-line react/react-in-jsx-scope
    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default withDeletePanier;
