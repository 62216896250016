import { AddIcon, CheckIcon, EditIcon } from '@chakra-ui/icons';
import { Button, Flex, Image, Input, Spinner, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { DEFAULT_USER_IMG } from '../../config';
import { useFetchData } from '../../hooks';

export function UserDetails() {
  const { spectateurData } = useFetchData();
  const [isEditing, setIsEditing] = useState(false);
  const [newNom, setNewNom] = useState('');
  const [newPrenom, setNewPrenom] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const currentSpectateur = spectateurData.spectateur;

  if (currentSpectateur) {
    /*
     * User info
     */
    const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(false);
    const handleEditClick = () => {
      setIsEditing(true);
      setNewPrenom(currentSpectateur.prenom.stringValue);
      setNewNom(currentSpectateur.nom.stringValue);
    };

    const handleUpdateUserInfo = async () => {
      if (newNom === '' || newPrenom === '') {
        setErrorMessage('Both name and prenom must be filled out.');
        return;
      }

      setErrorMessage(null);
      setIsLoadingUserInfo(true);

      const updatedData = {
        nom: newNom,
        prenom: newPrenom,
      };

      const result = await spectateurData.updateDetails(updatedData);

      if (result?.success) {
        setNewNom('');
        setNewPrenom('');
      } else {
        setErrorMessage(result?.message || 'An unknown error occurred.');
      }
      setIsLoadingUserInfo(false);
    };

    const handleConfirmClick = async () => {
      setIsEditing(false);
      await handleUpdateUserInfo();
    };

    /*
     * Image
     */
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);

    const handleAddImageClick = () => {
      fileInputRef.current?.click();
    };

    const handleUpdateProfilePicture = async (base64Image: string) => {
      setErrorMessage(null);
      setIsLoadingProfilePicture(true);
      const result = await spectateurData.updatePicture(base64Image);
      setIsLoadingProfilePicture(false);
      if (!result?.success) {
        setErrorMessage(result?.message || 'An unknown error occurred while updating the image.');
      }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      const reader = new FileReader();

      if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
        reader.onloadend = async () => {
          if (typeof reader.result === 'string') {
            const base64String = reader.result.replace('data:', '').replace(/^.+,/, '');
            await handleUpdateProfilePicture(base64String);
          }
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage('Juste images PNG et JPG.');
      }
    };

    return (
      <Flex direction={'column'} flex={1} alignItems={'center'} gap={2}>
        <Flex>
          <input
            type="file"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImageChange}
          />
          <Flex
            position="relative"
            width={'50px'}
            height={'50px'}
            bg={'white'}
            rounded={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor="pointer"
            onClick={handleAddImageClick}
          >
            <Image
              width={'45px'}
              height={'45px'}
              rounded={'50%'}
              src={
                currentSpectateur.photoProfil
                  ? currentSpectateur.photoProfil.stringValue
                  : DEFAULT_USER_IMG
              }
            />
            <Flex
              position="absolute"
              right="-5px"
              bottom="-5px"
              bg="blue.500"
              rounded="50%"
              height={'24px'}
              width={'24px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <AddIcon color="white" />
            </Flex>
          </Flex>
          {isLoadingProfilePicture && <Spinner />}
        </Flex>
        {isEditing ? (
          <>
            <Flex direction={'column'}>
              <Flex>
                <Input
                  placeholder="Prenom"
                  value={newPrenom}
                  onChange={(e) => setNewPrenom(e.target.value)}
                />
                <Input
                  placeholder="Nom"
                  value={newNom}
                  onChange={(e) => setNewNom(e.target.value)}
                />
                <Button onClick={handleConfirmClick}>
                  <CheckIcon />
                </Button>
              </Flex>
              <Text color="red.500">{errorMessage}</Text>
            </Flex>
          </>
        ) : (
          <Flex direction={'column'} gap={1}>
            <Flex justifyContent={'center'} alignItems={'center'} gap={1}>
              {isLoadingUserInfo && <Spinner />}
              <Text>{`${currentSpectateur.prenom.stringValue} ${currentSpectateur.nom.stringValue}`}</Text>
              <EditIcon cursor="pointer" onClick={handleEditClick} />
            </Flex>
            <Text color="red.500">{errorMessage}</Text>
          </Flex>
        )}
        <Text>{currentSpectateur.email.stringValue}</Text>
      </Flex>
    );
  }
  return null;
}
