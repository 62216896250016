import React, { useState } from 'react';
import { Flex, Text, Image, Box, HStack, Button, Center } from '@chakra-ui/react';
import { useApp, useFetchData } from '../../hooks';
import { EvenementResponse } from '../../models';
import { formatEventDate } from '../../utils';
import { LoadingComponent } from '../../shared-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LazyLoad from 'react-lazyload';

export function EventBox({ evenement }: { evenement: EvenementResponse }) {
  const { isMobile, url } = useApp();
  const [imageLoaded, setImageLoaded] = useState(false);
  const { spectateurData } = useFetchData();
  const values = spectateurData.spectateur.portefeuille?.arrayValue?.values;
  const countOccurrencesForEvent = (eventId, values) => {
    // Check if values is an array before using map
    if (Array.isArray(values)) {
      const documentIds = values.map((value) => {
        // Check if value and value.referenceValue exist before accessing split
        if (value && value.referenceValue) {
          const parts = value.referenceValue.split('/');
          return parts[parts.length - 3];
        } else {
          console.error('Invalid value structure:', value);
          return null; // or handle the case where value is not as expected
        }
      });

      // Count occurrences for the specific event
      const eventCount = documentIds.reduce((count, id) => {
        return id === eventId ? count + 1 : count;
      }, 0);

      return eventCount;
    } else {
      console.error('Values is not an array:', values);
      return 0; // or handle the case where values is not an array
    }
  };

  // Utilisation de la fonction pour obtenir le nombre d'occurrences pour un événement spécifique
  const eventId = evenement.id; // Remplacez ceci par l'ID de l'événement spécifique
  const occurrencesForEvent = countOccurrencesForEvent(eventId, values);

  return (
    <Center>
      <Flex
        p={5}
        direction={isMobile ? 'column' : 'row'}
        gap={isMobile ? 0 : 14}
        // bgGradient="linear(to-tl, #00143D, #000309BA )"
      >
        <Box
          borderRadius={'lg'}
          overflow="hidden"
          position="relative"
          width={'100%'}
          maxWidth={'300px'}
          height={'200px'}
        >
          {/* Placeholder */}
          <Flex
            width={'100%'}
            height={'200px'}
            position="absolute"
            alignItems="center"
            justifyContent="center"
            backgroundColor="grey"
            zIndex={imageLoaded ? -1 : 1}
          >
            {!imageLoaded && <LoadingComponent />}
          </Flex>

          {/* Image */}
          <LazyLoad height={'200px'} offset={100}>
            <Image
              onLoad={() => setImageLoaded(true)}
              display={imageLoaded ? 'block' : 'none'}
              width={'100%'}
              height={isMobile ? '100px' : '200px'}
              src={evenement.banniereImg.toString()}
              alt={evenement.nomEvenement.toString()}
              objectFit="cover"
            />
          </LazyLoad>
        </Box>

        <Flex direction={'column'} justifyContent={'center'} mt={isMobile ? -24 : 0}>
          <HStack>
            <Text fontWeight={'bold'} fontSize={isMobile ? '1rem' : '1.4rem'}>
              {evenement.nomEvenement.toString()}
            </Text>
            <Box
              style={{
                // backgroundColor: '#407BFF',
                // width: 40,
                //height: 40,
                //borderRadius: 30,
                marginLeft: 80,
                padding: 5,
              }}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <Text
                fontWeight={'bold'}
                fontSize={isMobile ? 'sm' : 'large'}
              >{`X${occurrencesForEvent}`}</Text>
            </Box>
          </HStack>

          <HStack pt={2}>
            <Image width={6} src={'  /icons/calender_for_ticket.svg'} />
            <Text fontSize={isMobile ? '0.8rem' : '1rem'}>
              Fini le{' '}
              <Box as="span" color="newLightBlue">
                {formatEventDate(new Date(evenement.dateFin))}
              </Box>{' '}
            </Text>
          </HStack>
          <HStack pt={2}>
            <Image width={5} src={'  /icons/location_for_ticket.svg'} />
            <Text fontSize={isMobile ? '0.8rem' : '1rem'}>
              {evenement.adresse.adresse.toString()}
            </Text>
          </HStack>

          <Button
            mt={5}
            width={'265px'}
            bg={'white'}
            //bg={'newLightBlue'}
            //border={'2px solid'}
            onClick={() => url.navigate(`/tickets/${evenement.id}`)}
            _hover={{
              background: 'linear-gradient(to right, #407BFF, transparent)',
              backgroundSize: '200% 100%',
              backgroundPosition: 'right top',
              transition: 'background 0.7s ease-in-out',
            }}
            // _hover={{
            //   background: 'linear-gradient(to right, #407BFF, transparent)',
            //   backgroundSize: '200% 100%',
            //   backgroundPosition: 'right top',
            //   transition: 'background 0.7s ease-in-out',
            // }}
          >
            <Text fontSize={isMobile ? '0.8rem' : '1rem'} color={'text.lightBlue'}>
              Afficher mes billets{' '}
            </Text>
          </Button>

          {/* <HStack pt={4}>
            <Button
              width={'130px'}
              bg={'transparent'}
              border={'2px solid #407BFF'}
              _hover={{
                background: 'linear-gradient(to right, #407BFF, transparent)',
                backgroundSize: '200% 100%',
                backgroundPosition: 'right top',
                transition: 'background 0.7s ease-in-out',
              }}
            >
              <Text fontSize={isMobile ? '0.8rem' : '1rem'} color={'white'}>
                Revendre{' '}
              </Text>
            </Button>
            <Button
              _hover={{
                background: 'linear-gradient(to right, #407BFF, transparent)',
                backgroundSize: '200% 100%',
                backgroundPosition: 'right top',
                transition: 'background 0.7s ease-in-out',
              }}
              width={'130px'}
              bg={'transparent'}
              border={'2px solid #407BFF'}
            >
              <Text fontSize={isMobile ? '0.8rem' : '1rem'} color={'white'}>
                {' '}
                Transférer{' '}
              </Text>
            </Button>
          </HStack> */}
        </Flex>
      </Flex>
    </Center>
  );
}
