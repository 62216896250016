import axios from 'axios';
import {
  LoginRequestModel,
  LoginSuccessResponseModel,
  RefreshTokenSuccessResponseModel,
  RegistrationDataModel,
  RegistrationSuccessResponseModel,
} from '../../models';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { handleApiError, handleApiResponse } from '../apiService';
import { auth } from '../../config';

export const login = async (
  credentials: LoginRequestModel
): Promise<LoginSuccessResponseModel | null | undefined> => {
  // const endpoint = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_API_KEY}`;
  try {
    const result = await signInWithEmailAndPassword(auth, credentials.email, credentials.password);
    console.log(result);
    return {
      displayName: result.user.displayName || '',
      email: result.user.email || '',
      idToken: (await result.user.getIdToken()) || '',
      localId: result.user.uid,
      refreshToken: result.user.refreshToken,
    };
    //return result;

    // const response = await axios.post(
    //   endpoint,
    //   {
    //     email: credentials.email,
    //     password: credentials.password,
    //     returnSecureToken: true,
    //   },
    //   {
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //   }
    // );
    //const responseData = handleApiResponse<UserCredential>(result, endpoint);
    // return responseData;
  } catch (error) {
    handleApiError(error, 'login');
  }
};

export const signup = async (
  registrationData: RegistrationDataModel
): Promise<LoginSuccessResponseModel | null | undefined> => {
  const endpoint = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/createSpectatorAccount`;
  try {
    const response = await axios.post(
      endpoint,
      {
        data: registrationData,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    handleApiResponse<RegistrationSuccessResponseModel>(response, endpoint);

    return await login({
      email: registrationData.email,
      password: registrationData.password,
    });
  } catch (error) {
    handleApiError(error, 'signup');
  }
};

export const refreshToken = async (
  refreshToken: string
): Promise<RefreshTokenSuccessResponseModel | null | undefined> => {
  const endpoint = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`;
  try {
    const response = await axios.post(
      endpoint,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return handleApiResponse<RefreshTokenSuccessResponseModel>(response, endpoint);
  } catch (error) {
    handleApiError(error, 'login');
  }
};
