import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { OrganisateurModel } from '../models';
import { RootState } from '../store';
import { apiService } from '../utils';

export interface OrganisateurState {
  organisateurById: Record<string, OrganisateurModel>;
  status: Record<string, 'idle' | 'loading' | 'succeeded' | 'failed'>;
  error: Record<string, string | undefined>;
}

const initialState: OrganisateurState = {
  organisateurById: {},
  status: {
    fetchOrganisateurs: 'idle',
  },
  error: {},
};

export const fetchOrganisateurs = createAsyncThunk('organisateurs/fetchOrganisateurs', async () => {
  return await apiService.fetchOrganisateurs();
});

const organisateursSlice = createSlice({
  name: 'organisateurs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganisateurs.pending, (state) => {
        state.status.fetchOrganisateurs = 'loading';
      })
      .addCase(fetchOrganisateurs.fulfilled, (state, action) => {
        if (action.payload) {
          state.status.fetchOrganisateurs = 'succeeded';
          action.payload.forEach((organisateur: OrganisateurModel) => {
            state.organisateurById[organisateur.id] = organisateur;
          });
        } else {
          state.status.fetchOrganisateurs = 'failed';
          state.error.fetchOrganisateurs = 'No data received';
        }
      })
      .addCase(fetchOrganisateurs.rejected, (state, action) => {
        state.status.fetchOrganisateurs = 'failed';
        state.error.fetchOrganisateurs = action.error.message || 'An error occurred';
      });
  },
});

export const selectAllOrganisateurs = (state: RootState) => state.organisateurs.organisateurById;

export default organisateursSlice.reducer;
