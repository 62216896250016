/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoginRequestModel, LoginSuccessResponseModel, RegistrationDataModel } from '../models';
import {
  fetchEvenementById,
  //fetchEvenementById,
  //fetchOrganisateurs,
  //fetchPopularEvenements,
  fetchSpectateurData,
  resetSpectateurState,
  selectAllEvenements,
} from '../slices';
import { loginUser, logout, refreshToken, signupUser } from '../slices/authSlice';
import { RootState, useAppDispatch } from '../store';
import { handleLog } from '../utils';
import { updateUserInfo, uploadProfilePicture } from '../utils/services';

export const useFetchData = (evenementId?: string) => {
  const dispatch = useAppDispatch();

  /*
   * EVENEMENTS
   */

  const evenementsById = useSelector(selectAllEvenements);
  const evenementsStatus = useSelector((state: RootState) => state.evenements.status);
  const evenementsError = useSelector((state: RootState) => state.evenements.error);
  //const evenementsNextPageToken = useSelector((state: RootState) => state.evenements.nextPageToken);

  const allEvenements = Object.values(evenementsById);

  function getEvenementById(evenementId: string) {
    handleLog([`Fetching evenement ${evenementId}...`]);
    dispatch(fetchEvenementById(evenementId));
  }
  useEffect(() => {
    if (evenementId) {
      getEvenementById(evenementId);
    }
  }, [evenementId]);

  // const loadMoreEvents = () => {
  //   handleLog(['Loading more events...']);
  //   dispatch(fetchEventsStart());
  //   // dispatch(fetchPopularEvenements({ page: 2, size: 2 }));
  // };

  const evenementsData = {
    list: allEvenements,
    byId: evenementsById,
    status: evenementsStatus,
    error: evenementsError,
    //token: evenementsNextPageToken,
    //loadMore: loadMoreEvents,
    fetchById: getEvenementById,
  };

  /*
   * AUTH AND SPECTATEUR
   */

  const authState = useSelector((state: RootState) => state.auth);
  const spectateurState = useSelector((state: RootState) => state.spectateur);

  useEffect(() => {
    if (authState.user) {
      const currentTime = Date.now();

      if (authState.tokenFetchTime && currentTime - authState.tokenFetchTime > 1800000) {
        dispatch(refreshToken(authState.user.refreshToken));
      }
    }
  }, [authState.user]);

  const login = async (credentials: LoginRequestModel) => {
    handleLog(['Logging in...']);

    const loginResult: { success: boolean; message?: string } = { success: false };

    try {
      const response = await dispatch(loginUser(credentials));

      if (response.meta.requestStatus === 'rejected') {
        if ('error' in response && 'message' in response.error) {
          const firebaseErrorCode = response.error.message;
          console.log(response.error.message);

          switch (firebaseErrorCode) {
            case 'Firebase: Error (auth/user-not-found).':
              loginResult.message =
                'Aucun utilisateur trouvé avec cette adresse email. Veuillez vous inscrire.';
              break;
            case 'Firebase: Error (auth/wrong-password).':
              loginResult.message = 'Mot de passe incorrect.';
              break;

            default:
              loginResult.message = "Une erreur inattendue s'est produite lors de la connexion.";
              break;
          }

          console.error('Erreur lors de la connexion :', firebaseErrorCode);
        }
      }

      if (response.meta.requestStatus === 'fulfilled') {
        const payload = response.payload as LoginSuccessResponseModel;
        const response2 = await dispatch(
          fetchSpectateurData({
            spectateurId: payload.localId,
            tokenId: payload.idToken,
          })
        );

        if (response2.meta.requestStatus === 'rejected') {
          if ('error' in response2 && 'message' in response2.error) {
            loginResult.message = response2.error.message;
          }
        }

        if (response2.meta.requestStatus === 'fulfilled') {
          loginResult.success = true;
        }
      }
    } catch (error) {
      loginResult.message = ' une erreur inconnue.';
    }

    return loginResult;
  };

  const signup = async (registrationData: RegistrationDataModel) => {
    handleLog(['Signing up...']);
    const signupResult: { success: boolean; message?: string } = { success: false };

    try {
      const response = await dispatch(signupUser(registrationData));

      if (response.meta.requestStatus === 'rejected') {
        if ('error' in response && 'message' in response.error) {
          signupResult.message = response.error.message;
        }
      }

      if (response.meta.requestStatus === 'fulfilled') {
        const payload = response.payload as LoginSuccessResponseModel;
        const response2 = await dispatch(
          fetchSpectateurData({
            spectateurId: payload.localId,
            tokenId: payload.idToken,
          })
        );

        if (response2.meta.requestStatus === 'rejected') {
          if ('error' in response2 && 'message' in response2.error) {
            signupResult.message = response2.error.message;
          }
        }

        if (response2.meta.requestStatus === 'fulfilled') {
          signupResult.success = true;
        }
      }
    } catch (error) {
      signupResult.message = "C'est passé un erreur inconnu.";
    }

    return signupResult;
  };

  const updateUserDetails = async (updatedData: { prenom: string; nom: string }) => {
    const updateResult: { success: boolean; message?: string } = { success: false };

    try {
      const tokenId = authState.user?.idToken;

      if (!tokenId) {
        updateResult.message = 'Token pas disponibile.';
        return updateResult;
      }

      const response = await updateUserInfo(updatedData, tokenId);

      if (response === null || response === undefined) {
        updateResult.message = 'An erreur est passé pendant la mise à jour des details.';
        return updateResult;
      }

      if (response.updated && spectateurState.spectateur) {
        const fetchResponse = await dispatch(
          fetchSpectateurData({ spectateurId: spectateurState.spectateur.id, tokenId })
        );

        if (fetchResponse.meta.requestStatus === 'rejected') {
          if ('error' in fetchResponse && 'message' in fetchResponse.error) {
            updateResult.message = fetchResponse.error.message;
          }
        }

        if (fetchResponse.meta.requestStatus === 'fulfilled') {
          updateResult.success = true;
        }
      } else {
        updateResult.message = 'Mise a jour pas réussie.';
      }
    } catch (error) {
      if (error instanceof Error) {
        updateResult.message = error.message;
      } else {
        updateResult.message = 'Erreur inconnu.';
      }
    }
    return updateResult;
  };

  async function updateUserProfilePicture(imageBase64: string) {
    const uploadResult = { success: false, message: '' as string | undefined };
    const tokenId = authState.user?.idToken;

    if (!tokenId) {
      uploadResult.message = 'Token non disponibile.';
      return uploadResult;
    }

    try {
      const uploadResponse = await uploadProfilePicture(imageBase64, tokenId);

      if (uploadResponse?.success && spectateurState.spectateur) {
        const fetchResponse = await dispatch(
          fetchSpectateurData({ spectateurId: spectateurState.spectateur.id, tokenId })
        );

        if (fetchResponse.meta.requestStatus === 'rejected') {
          if ('error' in fetchResponse && 'message' in fetchResponse.error) {
            uploadResult.message = fetchResponse.error.message;
          }
        }

        if (fetchResponse.meta.requestStatus === 'fulfilled') {
          uploadResult.success = true;
        }
      } else {
        uploadResult.message = 'Mise à jour pas réussie.';
      }
    } catch (error) {
      if (error instanceof Error) {
        uploadResult.message = error.message;
      } else {
        uploadResult.message = 'Erreur inconnu.';
      }
    }

    return uploadResult;
  }

  const logoutUser = () => {
    handleLog(['Logging out...']);
    dispatch(logout());
    dispatch(resetSpectateurState());
  };

  const spectateurData = {
    spectateur: spectateurState.spectateur,
    isLoading: authState.isLoading,
    error: authState.error,
    login,
    signup,
    logout: logoutUser,
    updateDetails: updateUserDetails,
    updatePicture: updateUserProfilePicture,
  };

  return { evenementsData, spectateurData };
};
