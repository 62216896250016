/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Event, NotFound, User, Home, ThankYou, Signup, Login, Payment } from './pages';
//import { fetchPopularEvenements } from './slices';
//import { handleLog } from './utils';
import withDeletePanier from './utils/BasketDeleteHOC';
import { Tickets } from './pages/Tickets';
import { register } from 'swiper/element/bundle';

// register Swiper custom elements

const App: React.FC = () => {
  register();
  // const [evenementsNextPageToken, setEvenementsNextPageToken] = useState<
  //   PaginationRequest | undefined
  // >();

  // const dispatchOrganisateursAction = () => {
  //   handleLog(['Fetching organisateurs...']);
  //   dispatch(fetchOrganisateurs());
  // };

  // const dispatchEvenementsAction = () => {
  //   //handleLog(['Fetching evenements...']);
  //   dispatch(fetchPopularEvenements({ page: 1, size: 2 }));
  // };

  // useEffect(() => {
  //   //dispatchOrganisateursAction();
  //   //dispatchEvenementsAction();

  //   const intervalId = setInterval(
  //     () => {
  //       //dispatchOrganisateursAction();
  //       dispatchEvenementsAction();
  //     },
  //     0.5 * 60 * 1000
  //   );
  //   return () => clearInterval(intervalId);
  // }, []);

  // useEffect(() => {
  //   const { pathname } = location;
  //   if (pathname.startsWith('/')) {
  //     const evenementId = pathname.split('/').pop();
  //     if (evenementId) {
  //       // dispatch(fetchEvenementById(evenementId));
  //     }
  //   }
  // }, [location]);

  useEffect(() => {
    localStorage.setItem('myValue', window.location.pathname);
  }, []);
  const storedValue = localStorage.getItem('myValue');
  console.log('Stored Value:', storedValue);

  const HomeWithDeletePanier = withDeletePanier(Home);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeWithDeletePanier />} />
        <Route path="/evenement/:evenementId" element={<Event />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/user" element={<User />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/tickets/:evenementId" element={<Tickets />} />
      </Routes>
    </Router>
  );
};
export default App;
