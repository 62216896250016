/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketState, TicketInBasketModel } from '../models';

const initialState: BasketState = {
  evenementId: null,
  tickets: [],
  error: null,
  status: 'idle',
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setIdEventToBuy: (state, action: PayloadAction<string>) => {
      state.evenementId = action.payload;
    },
    setBasketTickets: (state, action: PayloadAction<TicketInBasketModel[]>) => {
      state.tickets = action.payload;
    },
    updateTicketDetails: (
      state,
      action: PayloadAction<{
        ticketId: string;
        nom: string | undefined;
        prenom: string | undefined;
        index: number;
      }>
    ) => {
      if (action.payload.nom && !action.payload.prenom) {
        state.tickets[action.payload.index].nom = action.payload.nom;
      } else if (action.payload.prenom && !action.payload.nom) {
        state.tickets[action.payload.index].prenom = action.payload.prenom;
      }
    },
    clearBasket: (state) => {
      state.evenementId = null;
      state.tickets = [];
    },
  },
});

export const { setIdEventToBuy, setBasketTickets, updateTicketDetails, clearBasket } =
  basketSlice.actions;

export default basketSlice.reducer;
