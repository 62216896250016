import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { EvenementResponse } from '../../models';
import { useApp } from '../../hooks';

export const LieuSection = ({ currentEvenement }: { currentEvenement: EvenementResponse }) => {
  // const lat = currentEvenement.adresse.mapValue.fields.latitude;
  // const lng = currentEvenement.adresse.mapValue.fields.longitude;
  // const fields = currentEvenement.adresse.mapValue?.fields;

  const { isMobile } = useApp();

  // URL-encode each component of the address
  const encodedAdresse = encodeURIComponent(currentEvenement.adresse.adresse.stringValue);
  const encodedVille = encodeURIComponent(currentEvenement.adresse.ville.stringValue);
  const encodedPays = encodeURIComponent(currentEvenement.adresse.pays.stringValue);
  const encodedCodePostal = encodeURIComponent(currentEvenement.adresse.codePostal.stringValue);

  //Combine them into the final query string for 'q'
  const q = `${encodedAdresse},${encodedCodePostal} ${encodedVille},${encodedPays}`;

  return (
    <Flex direction={'column'} p={5}>
      <Text fontSize={isMobile ? 'x-large' : 'xx-large'} fontWeight="medium" mb={4} color={'white'}>
        Lieu
      </Text>
      <Flex mb={4} gap={5} color={'white'} fontWeight={'medium'} alignItems={'center'}>
        <Icon as={FaMapMarkerAlt} boxSize={6} m={2} />
        <Text>{currentEvenement.adresse.adresse.toString()}</Text>
      </Flex>
      {/* <Box width="100%" height="50vh">
        <iframe
          width="100%"
          height={isMobile ? '50%' : '100%'}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&center=${currentEvenement.adresse.latitude},${currentEvenement.adresse.longitude}&q=${q}`}
        />
      </Box> */}
    </Flex>
  );
};
