import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';

/* ChakraUI */
const theme = extendTheme({
  fonts: {
    body: 'Mitr, sans-serif',
    heading: 'Mitr, sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    semibold: 800,
    bold: 900,
  },
  colors: {
    gardient: 'linear(to-bl, #00143D, #000309BA )',
    newLightBlue: '#407BFF',
    // gardient: { bgGradient = 'linear(to-bl, #00143D, #000309BA )' },
    text: {
      yellow: '#FFD09B',
      blue: '#004766',
      //blue: '#00091B',
      lightBlue: '#015C82',
      lightBlue2: '#93B3C2',
      black: '#0E0E10',
      white: '#FFFFFE',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
