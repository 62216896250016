// import React from 'react';
// import { Flex, Image, Link, Box } from '@chakra-ui/react';
// import { useApp, useFetchData } from '../hooks';
// import { ConnectButton, SearchButton } from '../shared-components';

// import { useSelector } from 'react-redux';
// import { RootState } from '../store';

// //import { CategorieTicketModel } from '../models';

// export function Header() {
//   const basket = useSelector((state: RootState) => state.basket);
//   const { evenementsData, spectateurData } = useFetchData(basket.evenementId || undefined);
//   const currentEvenement = basket.evenementId ? evenementsData.byId[basket.evenementId] : undefined;

//   const handleClick = () => {
//     // const result = deletePanier({
//     //   evenementId: currentEvenement ? currentEvenement.id : 'undefined',
//     //   categoryId: ticketId,
//     //   panierId: auth.currentUser?.uid || '',
//     // });
//     // console.log(result);
//     // setTicketId(ticketId);
//     url.navigate('/');
//   };

//   const { isMobile, url } = useApp();

//   const isSearchVisible =
//     !location.pathname.includes('/login') && !location.pathname.includes('/signup');
//   return (
//     <Flex
//       position={'relative'}
//       zIndex={100}
//       as="header"
//       p={6}
//       bg="text.blue"
//       color="text.white"
//       align={isMobile ? 'flex-start' : 'center'}
//       justify={isMobile ? 'space-between' : 'space-between'}
//       direction={isMobile ? 'column' : 'row'}
//     >
//       {/* Mobile version */}
//       {isMobile && (
//         <Flex
//           flexDirection="column"
//           alignItems="flex-start"
//           justifyContent="space-between"
//           width="100%"
//         >
//           {/* Row 1: Logo and ConnectButton for mobile */}
//           <Flex alignItems="center" width="100%">
//             {/* Logo for mobile */}
//             <Box onClick={() => handleClick()}>
//               <Image src="/images/logo.png" alt="Logo" width={'150px'} />
//             </Box>

//             {/* Flexible empty element to create space */}
//             <Box flex={1} />

//             {/* ConnectButton for mobile */}
//             <Box>
//               <ConnectButton />
//             </Box>
//           </Flex>

//           {/* Row 2: SearchButton and "Je suis organisateur" for mobile */}

//           <Flex mt={4} width="100%" justifyContent="space-between" alignItems="center">
//             {/* Je suis organisateur for mobile */}
//             <Box ml={2}>
//               <Link
//                 fontSize={'xs'}
//                 textAlign={'center'}
//                 href={'https://nf-ticket.fr'}
//                 target="_blank"
//               >
//                 Je suis organisateur
//               </Link>
//             </Box>
//             {isSearchVisible && (
//               <Box>
//                 <SearchButton />
//               </Box>
//             )}
//           </Flex>
//         </Flex>
//       )}

//       {/* Desktop version */}
//       {!isMobile && (
//         <Flex alignItems="center" width="100%">
//           <Box onClick={() => url.navigate('/')}>
//             <Image src="/images/logo.png" alt="Logo" width={'150px'} />
//           </Box>
//           {/* SearchButton for desktop - take slightly less space */}
//           {isSearchVisible && (
//             <Box flex={1} marginRight={32} marginLeft={10}>
//               <SearchButton />
//             </Box>
//           )}

//           {/* Je suis organisateur for desktop */}
//           <Link
//             fontSize={'large'}
//             textAlign={'center'}
//             href={'https://nf-ticket.fr'}
//             target="_blank"
//           >
//             Je suis organisateur
//           </Link>

//           {/* ConnectButton for desktop */}
//           <Box ml={10}>
//             <ConnectButton />
//           </Box>
//         </Flex>
//       )}
//     </Flex>
//   );
// }
import React, { useState } from 'react';
import {
  Flex,
  Image,
  Link,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  HStack,
  Button,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useApp, useFetchData } from '../hooks';
import { ConnectButton, SearchButton } from '../shared-components';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { IoMdLogOut } from 'react-icons/io';

export function Header() {
  const handleClick = () => {
    url.navigate('/');
  };
  const { spectateurData } = useFetchData();
  const { isMobile, url } = useApp();
  const isSearchVisible =
    !location.pathname.includes('/login') && !location.pathname.includes('/signup');
  const handleLogoutClick = () => {
    spectateurData.logout();
  };

  function redirectToAppStore() {
    const userAgent = navigator.userAgent; 
    
    if (isMobile) {
      if (userAgent.match(/Android/i)) {
        // Rediriger vers le Google Play Store
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cobalte.nfticket';
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        // Rediriger vers l'App Store d'Apple
        window.location.href = 'https://apps.apple.com/fr/app/nf-ticket/id6463010132';
      }
    }
    window.location.href = 'https://www.nf-ticket.com/app';
  }
  const [isPopupVisible, setPopupVisibility] = useState(true);

  const closePopup = () => {
    setPopupVisibility(false);
  };
  return (
    <Flex
      position={'relative'}
      zIndex={100}
      as="header"
      p={6}
      bg="text.blue"
      color="text.white"
      align={isMobile ? 'flex-start' : 'center'}
      justify={isMobile ? 'space-between' : 'space-between'}
      direction={isMobile ? 'column' : 'row'}
      boxShadow={'0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04)'}
    >
      {isMobile && (
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Box>
              <Image src="/images/Calque_1.svg" alt="Logo" width={'45px'} onClick={handleClick} />
            </Box>
            <Box flex={1} />
            {isSearchVisible && (
              <Box mr={4} ml={2}>
                <SearchButton />
              </Box>
            )}
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HamburgerIcon color="white" />}
                variant="outline"
              />
              <MenuList alignItems={'start'}>
                <MenuItem>
                  <ConnectButton />
                </MenuItem>
                <Divider />
                <MenuItem mt={2} mb={2}>
                  <MdOutlineArrowOutward color={'#407BFF'} size={26} />
                  <Link
                    ml={3}
                    fontSize="sm"
                    fontWeight={'bold'}
                    textAlign="center"
                    href={'https://www.nf-ticket.com/'}
                    target="_blank"
                    color={'text.lightBlue'}
                    alignContent={'center'}
                  >
                    Je suis organisateur
                  </Link>
                </MenuItem>
                <Divider />
                <MenuItem mt={2}>
                  <IoMdLogOut color={'#407BFF'} size={26} />
                  <Link
                    ml={2}
                    color={'text.lightBlue'}
                    alignContent={'center'}
                    fontSize="sm"
                    fontWeight={'bold'}
                    textAlign="center"
                    onClick={handleLogoutClick}
                  >
                    Se déconnecter
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          {isPopupVisible && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                height: '70px',
                backgroundColor: '#00091B',
                borderTop: '2px solid #407BFF',
                borderBottom: '2px solid #407BFF',
                zIndex: 100,
                display: 'flex', // Utilisation de flexbox
                alignItems: 'center', // Alignement vertical au centre
                justifyContent: 'space-between', // Espacement égal entre les éléments
                padding: '0px 20px',
                // Ajout de marge intérieure pour l'espace entre les éléments
              }}
            >
              <div>
                <p style={{ fontSize: '13px', alignSelf: 'justify' }}>
                  Gardez vos billets à portée de main en téléchargeant l&apos;application!! 🚀
                </p>
              </div>

              {/* Bouton avec border radius et couleur bleue */}
              <Button
                style={{
                  width: '120px',
                  height: '30px',
                  borderRadius: '5px',
                  backgroundColor: '#407BFF',
                  color: 'white',
                  padding: '5px',
                  cursor: 'pointer',
                  border: 'none',
                  fontSize: '12px',
                  marginTop: '6px',
                  marginRight: '10px',
                }}
                _hover={{
                  background: 'linear-gradient(to right, #407BFF, #00091B)',
                  backgroundSize: '200% 100%',
                  backgroundPosition: 'right top',
                }}
                onClick={() => {
                  redirectToAppStore();
                  // Action à effectuer lors du clic sur le bouton
                }}
              >
                Télécharger
              </Button>
              <Image
                width={3}
                src={'/icons/croix.svg'}
                style={{ cursor: 'pointer', position: 'absolute', top: 4, right: 4 }}
                onClick={closePopup}
              />
            </div>
          )}
        </HStack>
      )}

      {!isMobile && (
        <Flex alignItems="center" width="100%">
          <Box>
            <Image src="/images/logo.png" alt="Logo" width={'150px'} onClick={handleClick} />
          </Box>
          <Box flex={1} />
          {isSearchVisible && (
            <Box width={'50%'} mr={20} ml={10}>
              <SearchButton />
            </Box>
          )}
          <Link
            fontSize={'large'}
            textAlign={'center'}
            href={'https://www.nf-ticket.com/'}
            target="_blank"
          >
            Je suis organisateur
          </Link>
          <Box ml={10}>
            <ConnectButton />
          </Box>
        </Flex>
      )}
    </Flex>
  );
}
