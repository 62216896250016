import { Flex } from '@chakra-ui/react';
import React from 'react';
import { MainContainer } from '../shared-layout';

export function ErrorComponent({ error }: { error: string }) {
  return (
    <MainContainer>
      <Flex>Error: {error.toString()}</Flex>
    </MainContainer>
  );
}
