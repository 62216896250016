import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  HStack,
  VStack,
  Text,
  Box,
  Link,
  Image,
  Divider,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useApp, useFetchData } from '../hooks';
import { formatEventDate } from '../utils';
import { EvenementResponse } from '../models';

export const SearchButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEvenements, setFilteredEvenements] = useState<EvenementResponse[]>([]);
  const { evenementsData } = useFetchData();
  const { isMobile } = useApp();
  const handleIconClick = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredEvenements([]);
      return;
    }

    const filtered = evenementsData.list.filter((event) => {
      // const { pays, ville, adresse } = event.adresse.mapValue.fields;
      const { typeEvenement, discipline, nomEvenement, adresse, organizerName } = event;
      const { idOrganisateur } = event;
      const nomEvenementLower = nomEvenement.toString().toLowerCase();
      const organizerNameLower = organizerName.toString().toLowerCase();
      const villeLower = adresse.ville.toString().toLowerCase();

      return (
        //pays.stringValue.includes(searchTerm) ||
        villeLower.includes(searchTerm.toLowerCase()) ||
        //villeLower === searchTerm.toLowerCase() ||
        // adresse.stringValue.includes(searchTerm) ||
        //codePostal.stringValue.includes(searchTerm) ||
        //typeEvenement.stringValue.includes(searchTerm) ||
        organizerNameLower.includes(searchTerm.toLowerCase()) ||
        nomEvenementLower.includes(searchTerm.toLowerCase())
      );
    });
    setFilteredEvenements(filtered);
  }, [searchTerm, evenementsData.list.length]);
  const isLastEvent = (filteredEvenements: EvenementResponse[], index: number) => {
    return filteredEvenements.length != index + 1;
  };

  return (
    <VStack align="stretch" spacing={2} position="relative" width={'100%'}>
      <InputGroup>
        <Input
          placeholder="Nom, adresse de l'évènement..."
          width={isMobile ? '100%' : isOpen ? '100%' : '0'}
          opacity={isOpen ? 1 : 0}
          bg="#004766"
          //bg={'text.lightBlue2'}
          fontSize={isMobile ? 14 : 18}
          transition="all 0.3s"
          value={searchTerm}
          focusBorderColor="text.lightBlue2"
          borderBottom={searchTerm ? 'none' : '1px solid white'}
          onChange={handleInputChange}
        />
        <InputRightElement>
          <IconButton
            colorScheme={'white'}
            bg={isOpen ? '' : 'text.lightBlue2'}
            aria-label="Search"
            icon={<SearchIcon />}
            isRound
            onClick={handleIconClick}
          />
        </InputRightElement>
      </InputGroup>

      {isOpen && filteredEvenements.length > 0 && (
        <Box
          overflowY="auto"
          maxHeight={isMobile ? '100vh' : '300px'}
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '100px', // Adjust the radius for a rounded appearance
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'white', // Adjust the color and opacity
              borderRadius: '90px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'rgba(79, 129, 189, 0.7)',
              borderRadius: '9px',
            },
          }}
          borderRadius="10"
          roundedTop="0"
          position="absolute"
          zIndex="2"
          bg="#004766"
          width="100%"
          top={'20px'}
          mt={4}
          boxShadow="md"
          borderWidth="1px"
        >
          <VStack align="start" spacing={5} p={3}>
            {filteredEvenements.map((evenement, index) => (
              <Link key={index} href={`/evenement/${evenement.id}`} width={'100%'}>
                <HStack>
                  <Image
                    borderRadius="20%"
                    boxSize="50px"
                    src={evenement.banniereImg.toString()}
                    alt="banniereImg"
                  />
                  <VStack align="start" pb={3}>
                    <Text as="b">{evenement.nomEvenement.toString()}</Text>
                    <Text as="sub">{formatEventDate(evenement.dateFin)}</Text>
                    <Text as="sub" mt={3}>
                      {evenement.adresse.ville.toString()}
                    </Text>
                  </VStack>
                </HStack>
                {isLastEvent(filteredEvenements, index) && (
                  <Divider mt={4} orientation="horizontal" />
                )}
              </Link>
            ))}
          </VStack>
        </Box>
      )}
    </VStack>
  );
};
