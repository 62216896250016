import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Icon, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useApp } from '../../hooks';
import {
  CategorieTicketModel,
  EvenementResponse,
  //OrganisateurModel,
  TicketInBasketModel,
} from '../../models';
import { formatEventDate, formatEventTime, sortedAvailableTicket } from '../../utils';

export function DetailsSection({
  currentEvenement,
  //currentOrganisateur,
  availableTickets,
  proceedToPayment,
}: {
  currentEvenement: EvenementResponse;
  availableTickets: CategorieTicketModel[];
  proceedToPayment: (
    evenement: EvenementResponse,
    selectedTickets: TicketInBasketModel[],
    isTicketFreeStatus: { [key: string]: boolean }
  ) => void;
}) {
  const { isMobile } = useApp();
  const isEventFinished = new Date(currentEvenement.dateFin) < new Date();
  const sortedAvailableTickets = sortedAvailableTicket([...availableTickets]);

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} gap={5} justifyContent={'center'}>
      {isMobile && (
        <Flex>
          <Image
            width={'100%'}
            maxHeight={'500px'}
            src={currentEvenement.banniereImg.toString()}
            alt={currentEvenement.banniereImg.toString()}
            rounded={'12px'}
          />
        </Flex>
      )}
      <Flex width={'70vw'}>
        <Flex flexDirection="column">
          {/* Event name */}
          <Text
            fontSize={isMobile ? 'x-large' : 'xx-large'}
            fontWeight="bold"
            mb={1}
            color={'white'}
          >
            {currentEvenement.nomEvenement.toString()}
          </Text>

          {/* Organiser name */}

          {/* {'currentOrganisateur' ? (
            <Text fontSize="large" fontWeight={'normal'} color={'white'} mb={4}>
              Par 
              <Link color={'text.lightBlue2'} href={`#`}>
                {currentEvenement.organizerName.toString()}
              </Link>
            </Text>
          ) : (
            <Text fontSize="md" fontWeight={'medium'} color={'white'} mb={4}>
              pas dispo
            </Text>
          )} */}

          {/* Dates */}
          <Flex mb={4} gap={5} color={'white'} fontWeight={'medium'} alignItems={'center'}>
            <Icon as={CalendarIcon} boxSize={10} m={2} color={'text.lightBlue2'} />
            <Flex flexDirection="column" gap={2}>
              <Flex justifyContent="space-between" gap={5}>
                <Text fontSize={isMobile ? 'small' : 'medium'}>DU</Text>
                <Text color={'text.yellow'} fontSize={isMobile ? 'small' : 'medium'}>
                  {formatEventDate(currentEvenement.dateDebut)}
                </Text>
                <Text color={'text.lightBlue2'} fontSize={isMobile ? 'small' : 'medium'}>
                  à
                </Text>
                <Text fontSize={isMobile ? 'small' : 'medium'}>
                  {formatEventTime(currentEvenement.dateDebut)}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" gap={5}>
                <Text fontSize={isMobile ? 'small' : 'medium'}>DU</Text>
                <Text color={'text.yellow'} fontSize={isMobile ? 'small' : 'medium'}>
                  {formatEventDate(currentEvenement.dateFin)}
                </Text>
                <Text color={'text.lightBlue2'} fontSize={isMobile ? 'small' : 'medium'}>
                  à{' '}
                </Text>
                <Text fontSize={isMobile ? 'small' : 'medium'}>
                  {formatEventTime(new Date(currentEvenement.dateFin))}
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Box borderBottom="2px solid white" my={2} ml={'65px'} />

          {/* Location */}

          <Flex mb={4} gap={5} color={'white'} fontWeight={'medium'} alignItems={'center'}>
            <Icon as={FaMapMarkerAlt} boxSize={10} m={2} color={'text.lightBlue2'} />
            <Text>{currentEvenement.adresse.adresse.toString()}</Text>
          </Flex>
        </Flex>
      </Flex>

      {!isMobile && (
        <Flex>
          <Image
            width={'60vw'}
            maxHeight={'500px'}
            src={currentEvenement.banniereImg.toString()}
            alt={currentEvenement.banniereImg.toString()}
            rounded={'12px'}
          />
        </Flex>
      )}
    </Flex>
  );
}
