import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/authSlice';
import basketSlice from './slices/basketSlice';
import evenementsSlice from './slices/evenementSlice';
import organisateursSlice from './slices/organisateursSlice';
import spectateurSlice from './slices/spectateurSlice';
import eventSlice from './slices/eventSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  evenements: evenementsSlice,
  organisateurs: organisateursSlice,
  spectateur: spectateurSlice,
  basket: basketSlice,
  events: eventSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};

const finalReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: finalReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
// store.subscribe(() => {
//   console.log('State mis à jour :', store.getState());
// });

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const persistor = persistStore(store);
export default store;
