import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { EvenementResponse } from '../models';
import { RootState } from '../store';
import { apiService } from '../utils';

export interface EvenementState {
  evenementsById: Record<string, EvenementResponse>;
  status: Record<string, 'idle' | 'loading' | 'succeeded' | 'failed'>;
  error: Record<string, string | undefined>;
}

const initialState: EvenementState = {
  evenementsById: {},
  status: {
    fetchPopularEvenements: 'idle',
    fetchEvenementById: 'idle',
  },
  error: {},
};

export const fetchPopularEvenements = createAsyncThunk(
  'evenements/fetchPopularEvenements',
  async () => {
    return await apiService.fetchPopularEvenements({ page: 1, size: 10 });
  }
);

export const fetchEvenementById = createAsyncThunk(
  'evenements/fetchEvenementById',
  async (id: string) => {
    console.log('id de l event :', id);
    return await apiService.fetchEvenementById(id);
  }
);

const evenementsSlice = createSlice({
  name: 'evenements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPopularEvenements.pending, (state) => {
        state.status.fetchPopularEvenements = 'loading';
      })
      .addCase(fetchPopularEvenements.fulfilled, (state, action) => {
        if (action.payload) {
          state.status.fetchPopularEvenements = 'succeeded';
        } else {
          state.status.fetchPopularEvenements = 'failed';
          state.error.fetchPopularEvenements = 'No data received';
        }
      })
      .addCase(fetchPopularEvenements.rejected, (state, action) => {
        state.status.fetchPopularEvenements = 'failed';
        state.error.fetchPopularEvenements = action.error.message || 'An error occurred';
      });
    builder
      .addCase(fetchEvenementById.pending, (state) => {
        state.status.fetchEvenementById = 'loading';
      })
      .addCase(fetchEvenementById.fulfilled, (state, action) => {
        state.status.fetchEvenementById = action.payload ? 'succeeded' : 'failed';
        state.evenementsById[action.payload?.id] = action.payload;
        state.error.fetchEvenementById = action.payload ? undefined : 'No data received';
      })
      .addCase(fetchEvenementById.rejected, (state, action) => {
        state.status.fetchEvenementById = 'failed';
        state.error.fetchEvenementById = action.error.message || 'An error occurred';
      });
  },
});
export const selectEvenementsState = (state: RootState) => state.evenements;
export const selectAllEvenements = createSelector(
  [selectEvenementsState],
  (evenements) => evenements.evenementsById
);

export default evenementsSlice.reducer;
