import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SpectateurModel } from '../models';
import { fetchSpectateur, uploadProfilePicture } from '../utils/services';

interface SpectateurState {
  spectateur: SpectateurModel | null;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: SpectateurState = {
  spectateur: null,
  isLoading: false,
  error: undefined,
};

export const fetchSpectateurData = createAsyncThunk(
  'spectateur/fetch',
  async ({ spectateurId, tokenId }: { spectateurId: string; tokenId: string }) => {
    return await fetchSpectateur(spectateurId, tokenId);
  }
);

export const uploadProfilePictureData = createAsyncThunk(
  'spectateur/uploadProfilePicture',
  async ({ imageData, tokenId }: { imageData: string; tokenId: string }) => {
    return await uploadProfilePicture(imageData, tokenId);
  }
);

const spectateurSlice = createSlice({
  name: 'spectateur',
  initialState,
  reducers: {
    resetSpectateurState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpectateurData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(
        fetchSpectateurData.fulfilled,
        (state, action: PayloadAction<SpectateurModel | null | undefined>) => {
          state.isLoading = false;
          if (action.payload) {
            state.spectateur = action.payload;
          } else {
            state.error = 'No data received';
          }
        }
      )
      .addCase(fetchSpectateurData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(uploadProfilePictureData.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(uploadProfilePictureData.fulfilled, (state) => {
        state.isLoading = false;
        // if (action.payload && state.spectateur) {
        //   state.spectateur.photoProfil = {
        //     string: action.payload.url,
        //   };
        // } else {
        //   state.error = 'Failed to upload profile picture';
        // }
      })
      .addCase(uploadProfilePictureData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetSpectateurState } = spectateurSlice.actions;

export default spectateurSlice.reducer;
