import React, { useState } from 'react';
import { Flex, Text, Image, Box } from '@chakra-ui/react';
//import { useFetchData } from '../../hooks';
import { EvenementResponse } from '../../models';
import { formatEventDate, sortedAvailableTicket } from '../../utils';
import { LoadingComponent } from '../../shared-components';
import './EventSection.css';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

export function EventSection({ evenements }: { evenements: EvenementResponse[] }) {
  console.log('event section', evenements);
  const [imageLoaded, setImageLoaded] = useState<Record<string, boolean>>({});

  const navigate = useNavigate();

  const handleClick = (url: string) => {
    navigate(url);
  };

  if (!evenements || evenements.length === 0) {
    return (
      <Flex alignSelf={'center'}>
        <LoadingComponent />
      </Flex>
    );
  }

  return (
    <section>
      {evenements.map((evenement) => (
        <div
          key={evenement.id}
          className="event-card"
          onClick={() => handleClick(`/evenement/${evenement.id}`)}
        >
          {evenement.id ? (
            <Flex flexDirection="column" width={'100%'}>
              <Box
                borderTopLeftRadius={'md'}
                borderTopRightRadius={'md'}
                overflow="hidden"
                position="relative"
                width={'100%'}
                height={'200px'}
              >
                {/* Placeholder */}
                <Flex
                  width={'100%'}
                  height={'200px'}
                  position="absolute"
                  alignItems="center"
                  justifyContent="center"
                  zIndex={imageLoaded[evenement.id] ? -1 : 1}
                >
                  {!imageLoaded[evenement.id] && <LoadingComponent />}
                </Flex>

                {/* Image */}
                <LazyLoad height={'200px'} offset={100}>
                  <Image
                    onLoad={() => {
                      setImageLoaded((prevImageLoaded) => ({
                        ...prevImageLoaded,
                        [evenement.id]: true,
                      }));
                    }}
                    display={imageLoaded[evenement.id] ? 'block' : 'none'}
                    width={'100%'}
                    height={'200px'}
                    src={evenement.banniereImg.toString()}
                    alt={evenement.nomEvenement.stringValue}
                    objectFit="cover"
                  />
                </LazyLoad>
              </Box>
              <div className="card-content">
                <Text mt={2} fontSize={'lg'} fontWeight="bold" color={'white'}>
                  {evenement.nomEvenement.toString()}
                </Text>
                <Text fontWeight="small" color={'lightBlue'}>
                  {evenement.idOrganisateur
                    ? evenement.organizerName.toString()
                    : 'Organisateur pas disponible'}
                </Text>
                <Flex gap={5} mt={2}>
                  <Text fontWeight="medium" color={'text.yellow'}>
                    {formatEventDate(new Date(evenement.dateDebut.toString()))}
                  </Text>

                  <Text fontWeight="medium" color={'lightBlue'}>
                    À partir de {sortedAvailableTicket(evenement.ticketsCategories)[0]?.prix || 0} €
                  </Text>
                </Flex>
              </div>
            </Flex>
          ) : (
            <LoadingComponent />
          )}
        </div>
      ))}
    </section>
  );
}
