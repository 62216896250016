import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useApp, useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
import { LogoutButton } from './LogoutButton';
import { UserDetails } from './UserDetails';
import { UserTabs } from './UserTabs';
import { Wallet } from './Wallet';
import { FaArrowLeftLong } from 'react-icons/fa6';

export function User() {
  const { isMobile, url } = useApp();
  const { spectateurData } = useFetchData();

  // if (!spectateurData.spectateur) {
  //   url.navigate('/login');
  // }

  React.useEffect(() => {
    if (!spectateurData.spectateur) {
      url.navigate('/login');
    }
  }, [spectateurData.spectateur, url]);

  if (spectateurData.spectateur) {
    return (
      <MainContainer>
        <Flex direction={'row'} justifyContent={'center'}>
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            maxWidth={isMobile ? '100%' : '70vw'}
            p={5}
            bg={'text.lightBlue'}
            //bgGradient="linear(to-bl, #00143D, #000309BA )"
            flex={1}
            direction={'column'}
            color={'white'}
            gap={5}
          >
            <Flex width={'100%'} justifyContent={'space-between'}>
              <FaArrowLeftLong
                aria-label={'backButton'}
                fontSize={'xx-large'}
                //rounded={'50%'}
                onClick={() => url.navigate('/')}
              />
              <UserDetails />
            </Flex>
            <UserTabs />
            <LogoutButton />
            <Wallet spectateur={spectateurData.spectateur} />
          </Flex>
        </Flex>
      </MainContainer>
    );
  }
  return null;
}
