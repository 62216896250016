import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { EvenementResponse } from '../../models';
import { useApp } from '../../hooks';
export const OrganisateurSection = ({
  currentEvenement,
}: {
  currentEvenement: EvenementResponse;
}) => {
  const { isMobile } = useApp();
  return (
    <Flex direction={'column'} p={5}>
      <Text fontSize={isMobile ? 'x-large' : 'xx-large'} fontWeight="medium" mb={4} color={'white'}>
        Organisé par
      </Text>
      <Flex direction={'row'}>
        <Text fontSize="md" fontWeight="normal" color={'white'}>
          {currentEvenement.organizerName.toString()}
        </Text>
      </Flex>
    </Flex>
  );
};
