/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import {
  CategorieTicketApiResponseModel,
  CategorieTicketModel,
  IRemovePanierRequest,
  IStripePaymentIntentrequest,
  ITicketRequest,
  IValidateCartAndTicketRequest,
} from '../../models';
import { FIRESTORE_BASE_URL, handleApiError, handleApiResponse } from '../apiService';
import { HttpsCallableResult, httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../config';

const addTicketFunction = httpsCallable(functions, 'addTicket');
const removeTicketFunction = httpsCallable(functions, 'removeTicket');
const stripePaymentIntentFunction = httpsCallable(functions, 'stripePaymentIntentCall');
const validateCartAndAssignTicketFunction = httpsCallable(
  functions,
  'validateCartAndAssignTicketsCall'
);
const deletePanierFunction = httpsCallable(functions, 'deleteCart');
export const fetchTicketsByEvenementId = async (
  id: string
): Promise<CategorieTicketModel[] | null | undefined> => {
  const endpoint = `${FIRESTORE_BASE_URL}/ListeEvenements/${id}/ListeCategoriesTicket`;

  try {
    const response = await axios.get(endpoint);
    const data = handleApiResponse<CategorieTicketApiResponseModel>(response, endpoint);
    // const response2 = await axios.get(endpoint);
    // const data2 = handleApiResponse<CategorieTicketApiResponseModel>(response2, endpoint2);

    const ticketData = data.documents.map((doc) => doc.fields as CategorieTicketModel);
    return ticketData;
  } catch (error) {
    handleApiError(error, 'fetchTicketsByEvenementId');
  }
};
// export const fetchListeTickets = async (id: string, tokenId: string): Promise<any> => {
//   const config = {
//     headers: { Authorization: `Bearer ${tokenId}` },
//   };
//   const endpoint = `${FIRESTORE_BASE_URL}/ListeEvenements/${id}/`;

//   try {
//     const response = await axios.get(endpoint, config);
//     const data = handleApiResponse<any>(response, endpoint);

//     console.log(data);
//     //const ticketData = data.documents.map((doc) => doc.fields as CategorieTicketModel);
//     return data;
//   } catch (error) {
//     handleApiError(error, 'fetchListeTickets');
//   }
// };

export const addTicket = async (addTicketRequest: ITicketRequest): Promise<any> => {
  try {
    console.log(addTicketRequest);
    await addTicketFunction({
      idEvenement: addTicketRequest.evenementId,
      idCategorie: addTicketRequest.categoryId,
    });
  } catch (error) {
    handleApiError(error, 'addTicket');
  }
};

export const removeTicket = async (removeTicketRequest: ITicketRequest): Promise<any> => {
  try {
    console.log(removeTicketRequest);
    await removeTicketFunction({
      idEvenement: removeTicketRequest.evenementId,
      idCategorie: removeTicketRequest.categoryId,
    });
  } catch (error) {
    handleApiError(error, 'removeTicket');
  }
};

export const stripePaymentIntent = async (
  stripePaymentIntentRequest: IStripePaymentIntentrequest
): Promise<HttpsCallableResult<unknown>> => {
  try {
    console.log(stripePaymentIntentRequest);
    const response = await stripePaymentIntentFunction({
      email: auth.currentUser?.email || '',
      amount: stripePaymentIntentRequest.amount,
    });
    return response;
  } catch (error) {
    handleApiError(error, 'stripePaymentIntent');
    throw error;
  }
};
export const validateCartAndAssignTicket = async (
  ValidateCartAndTicketRequest: IValidateCartAndTicketRequest
): Promise<any> => {
  try {
    await validateCartAndAssignTicketFunction({
      panierId: ValidateCartAndTicketRequest.panierId,
      paymentIntentID: ValidateCartAndTicketRequest.paymentIntentID,
      email: ValidateCartAndTicketRequest.email,
    });
    // console.log(result);
  } catch (error) {
    handleApiError(error, 'validateCartAndAssignTicket');
  }
};
export const deletePanier = async (RemovePanierRequest: IRemovePanierRequest): Promise<any> => {
  try {
    await deletePanierFunction({
      cartId: RemovePanierRequest.cartId,
    });
  } catch (error) {
    handleApiError(error, 'deleteCart');
  }
};
