import React, { useState, useEffect } from 'react';
import { Button, Flex, Input, Link, Spinner, Text } from '@chakra-ui/react';
//import { useSelector } from 'react-redux';
import { useApp, useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
//import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

export function Login() {
  const { isMobile, url } = useApp();
  const { spectateurData } = useFetchData();
  //const basket = useSelector((state: RootState) => state.basket);

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(clearBasket());
  // }, [dispatch]);

  useEffect(() => {
    validateInput();
  }, [email, password]);

  const validateInput = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (emailPattern.test(email)) {
      setIsValid(true);
      setErrorMsg('');
    } else {
      setIsValid(false);
      if (email) {
        //setErrorMsg('Adresse email invalide. Veuillez entrer une adresse email valide.');
        setErrorMsg('');
      }
    }
  };

  const url1 = useNavigate();
  const handleLogin = async () => {
    if (isLoading) {
      return;
    }
    if (isValid) {
      setIsLoading(true);
      const result = await spectateurData.login({ email, password });
      setIsLoading(false);
      if (!result.success) {
        if (result.message === 'EMAIL_NOT_FOUND') {
          setErrorMsg('Adresse email non trouvée dans la base de données. Veuillez vous inscrire.');
        } else if (result.message === 'INVALID_PASSWORD') {
          setErrorMsg('Mot de passe incorrect. Veuillez réessayer.');
        } else {
          setErrorMsg(result.message || 'Erreur inconnue lors de la connexion');
        }
      } else {
        setErrorMsg(
          errorMsg +
            "Une erreur inattendue s'est produite lors de la connexion. Veuillez réessayer."
        );

        if (localStorage.getItem('myValue').includes('evenement')) {
          url.navigate(-1);
          console.log(location.pathname, 'je suis event');
          //console.log(location.pathname.startsWith('/login'));
        } else {
          console.log(location.pathname);
          url1('/'); // Vous pouvez ajuster cela en fonction de la structure de vos URL
          console.log(location.pathname, 'je suis dans la condition home');
        }
      }
    }
  };

  return (
    <MainContainer>
      <Flex
        width={'800px'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'white'}
        flex={1}
        direction={isMobile ? 'column' : 'row'}
        alignSelf={'center'}
      >
        <Flex
          borderRadius={'3% 0% 0% 3%'}
          width={'60%'}
          height={'110%'}
          bg={'white'}
          color={'black'}
          direction={'column'}
          gap={5}
          p={10}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text fontSize={'xx-large'} fontWeight={'bold'}>
            S&apos;identifier
          </Text>
          <Text mt={-5} fontSize={'medium'}>
            ou utilisez votre compte
          </Text>
          <Input
            width={'300px'}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Input
            width={'300px'}
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          <Text mt={'-5'} color="red.500" fontSize="12px" textAlign={'center'}>
            {errorMsg}
          </Text>
          {/* TODO Mot de passe oublié link */}
          <Link color={'text.lightBlue'} href={'#'}>
            Mot de passe oublié?
          </Link>
          <Flex alignItems={'center'} gap={5}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Button
                backgroundColor={'transparent'}
                border={'1px'}
                borderColor={'text.lightBlue'}
                borderRadius={'20'}
                width={'170px'}
                onClick={handleLogin}
                isDisabled={!isValid}
              >
                S&apos;IDENTIFIER
              </Button>
            )}
          </Flex>
        </Flex>
        <Flex
          borderRadius={'0% 3% 3% 0%'}
          width={'60%'}
          height={'110%'}
          bgGradient="linear(to-br, #142025, #005c82 )"
          color={'white'}
          direction={'column'}
          gap={9}
          p={10}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Text textAlign={'center'} fontSize={'xx-large'} fontWeight={'bold'}>
            Bienvenue chez NF-Ticket!
          </Text>
          <Text textAlign={'center'} fontSize={'medium'}>
            Créez-vous un compte et commencez votre voyage avec nous
          </Text>
          <Button
            backgroundColor={'transparent'}
            border={'1px'}
            borderColor={'white'}
            borderRadius={'20'}
            width={'170px'}
            mt={'60px'}
            onClick={() => url.navigate('/signup')}
            alignSelf={'center'}
            color={'white'}
          >
            S&apos;INSCRIRE
          </Button>
        </Flex>
      </Flex>
    </MainContainer>
  );
}
