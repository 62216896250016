import axios from 'axios';
import {
  SpectateurApiResponseModel,
  SpectateurModel,
  SpectateurPrivateInformationApiResponseModel,
} from '../../models';
import { FIRESTORE_BASE_URL, handleApiError, handleApiResponse } from '../apiService';

export const fetchSpectateur = async (
  spectateurId: string,
  tokenId: string
): Promise<SpectateurModel | null | undefined> => {
  const config = {
    headers: { Authorization: `Bearer ${tokenId}` },
  };

  const endpoint1 = `${FIRESTORE_BASE_URL}/ListeSpectateurs/${spectateurId}`;
  console.log('endpoint1', endpoint1);
  const endpoint2 = `${FIRESTORE_BASE_URL}/ListeSpectateurs/${spectateurId}/Informations/Prive`;

  try {
    const response1 = await axios.get(endpoint1, config);
    const data1 = handleApiResponse<SpectateurApiResponseModel>(response1, endpoint1);

    const response2 = await axios.get(endpoint2, config);
    const data2 = handleApiResponse<SpectateurPrivateInformationApiResponseModel>(
      response2,
      endpoint2
    );

    if (data1 && data2) {
      const id = spectateurId;
      const payload: SpectateurModel = {
        id,
        ...data1.fields,
        addressWallet: data2.fields.addressWallet,
        portefeuille: data2.fields.portefeuille,
      };
      return payload;
    }
  } catch (error) {
    handleApiError(error, 'fetchSpectateur');
  }
};

export const uploadProfilePicture = async (
  imageData: string,
  tokenId: string
): Promise<{ success: boolean; url: string } | null | undefined> => {
  const endpoint = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/uploadProfilePicture`;

  try {
    const response = await axios.post(
      endpoint,
      {
        data: {
          imageData: imageData,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    const data = handleApiResponse<{ result: { success: boolean; url: string } }>(
      response,
      endpoint
    );

    return data.result;
  } catch (error) {
    handleApiError(error, 'uploadProfilePicture');
  }
};

export const updateUserInfo = async (
  updatedData: { prenom: string; nom: string },
  tokenId: string
): Promise<{ updated: boolean } | null | undefined> => {
  const endpoint = `${process.env.REACT_APP_CLOUD_FUNCTIONS_URL}/updateUserInfo`;

  try {
    const response = await axios.post(
      endpoint,
      { data: updatedData },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenId}`,
        },
      }
    );

    const result = handleApiResponse<{ result: { updated: boolean } }>(response, endpoint);
    return result.result;
  } catch (error) {
    handleApiError(error, 'updateUserInfo');
  }
};
