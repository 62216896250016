import React, { useState } from 'react';
import { Flex, Text, IconButton } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { SpectateurModel } from '../../models';

export function Wallet({ spectateur }: { spectateur: SpectateurModel }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(spectateur.prenom.stringValue)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 500);
      })
      .catch((err) => {
        // Handle errors if any
        console.error('Could not copy text: ', err);
      });
  };

  return (
    <Flex direction={'column'} width={'100%'} mt={'150px'}>
      <Text>Wallet address</Text>
      <Flex alignItems="center" gap={2}>
        <Text color={isCopied ? 'green' : 'text.lightBlue2'} wordBreak={'break-word'}>
          {spectateur.addressWallet.stringValue}
        </Text>
        <IconButton icon={<CopyIcon />} aria-label="Copy to clipboard" onClick={handleCopyClick} />
      </Flex>
    </Flex>
  );
}
