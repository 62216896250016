import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { stripePaymentIntent, validateCartAndAssignTicket } from '../../utils/services';
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
} from '@chakra-ui/react';
import { HttpsCallableResult } from 'firebase/functions';
import { auth } from '../../config';
import { useSelector } from 'react-redux';
import { useApp, useFetchData } from '../../hooks';
import { RootState } from '../../store';
import { useLocation } from 'react-router-dom';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [showModal, setShowModal] = useState(false);
  const basket = useSelector((state: RootState) => state.basket);
  const { evenementsData, spectateurData } = useFetchData(basket.evenementId || undefined);
  const currentEvenement = basket.evenementId ? evenementsData.byId[basket.evenementId] : undefined;

  const { url } = useApp();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  if (!basket.evenementId || !currentEvenement || !spectateurData.spectateur) {
    return null;
  }
  const location = useLocation();
  const isTicketFreeStatus = location.state?.isTicketFreeStatus || {};

  const calculateTotal = () => {
    let total = 0;

    basket.tickets.forEach((ticketInBasket) => {
      const currentTicket = currentEvenement.ticketsCategories.find(
        (e) => e.id === ticketInBasket.ticketId
      );
      if (currentTicket) {
        total += currentTicket.prix;
      }
    });
    return Number(total.toFixed(2));
  };

  const handleModal = async () => {
    try {
      setModalLoading(true);
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error('Card element not found.');
        return;
      }

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        console.error(result.error.message);
      } else {
        // Payment succeeded
        console.log('Payment succeeded:');
        setPaymentStatus('success');
        const response = await validateCartAndAssignTicket({
          email: auth.currentUser?.email || '',
          panierId: auth.currentUser?.uid || '',
          paymentIntentID: result.paymentIntent.id,
        });
        url.navigate('/thankyou');
      }
    } finally {
      setModalLoading(false);
    }
  };

  const handlePayment = async () => {
    try {
      if (calculateTotal() != 0) {
        setLoading(true);
        const response: HttpsCallableResult<unknown> = await stripePaymentIntent({
          email: auth.currentUser?.email || '',
          amount: calculateTotal() * 100,
        });
        try {
          if (response && response.data) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data = response.data as { paymentIntent?: any };

            if (data && typeof data.paymentIntent !== 'undefined') {
              setClientSecret(data.paymentIntent);
              setShowModal(true);
            } else {
              console.error('Unexpected data format:', data);
            }
          } else {
            console.error('Response or response data is undefined.');
          }
        } catch (error) {
          console.error('Error accessing response data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        url.navigate('/thankyou');
      }
    } catch (error) {
      console.error('Error during payment:', error);
      setLoading(false);
    }
  };
  const handleCloseModal = () => {
    if (!loading) {
      setShowModal(false);
      setPaymentStatus('failed');
    }
  };

  return (
    <Flex alignItems="center" direction="column">
      <Button width="50vw" onClick={handlePayment} disabled={loading}>
        {loading ? <Spinner size="sm" color="blue.500" /> : 'Payer'}
      </Button>

      <Modal
        isOpen={showModal}
        onClose={handleCloseModal}
        size="xl"
        closeOnOverlayClick={!modalLoading}
        closeOnEsc={!modalLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Paiement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '18px',
                        color: '#424770',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                    hidePostalCode: true,
                  }}
                />
              </div>

              <Button width="100%" mt={3} mb={3} onClick={handleModal} disabled={modalLoading}>
                {modalLoading ? <Spinner size="sm" color="blue.500" /> : 'Payer'}
              </Button>
              {paymentStatus === 'success' && (
                <Flex>
                  <p
                    style={{
                      color: 'green',
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Paiement réussi!
                  </p>
                </Flex>
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CheckoutForm;
