import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp, useFetchData } from '../../hooks';
import { EvenementResponse, TicketInBasketModel } from '../../models';
import { ErrorComponent, LoadingComponent } from '../../shared-components';
import { MainContainer } from '../../shared-layout';
import { clearBasket, setBasketTickets, setIdEventToBuy } from '../../slices';
import { useAppDispatch } from '../../store';
import { OrganisateurSection } from './OrganisateurSection';
import { DetailsSection } from './DetailsSection';
import { LieuSection } from './LieuSection';
// import { TicketSection } from './TicketSection';
import './index.css';

export function Event() {
  const { evenementId } = useParams();
  const { evenementsData } = useFetchData(evenementId);
  const { isMobile } = useApp();
  const navigate = useNavigate();

  const currentEvenement = evenementId ? evenementsData.byId[evenementId] : undefined;

  /* Redux basket slice */
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearBasket());
  }, [dispatch]);

  function redirectToAppStore() {
    const userAgent = navigator.userAgent;

    if (isMobile) {
      if (userAgent.match(/Android/i)) {
        // Rediriger vers le Google Play Store
        return 'https://play.google.com/store/apps/details?id=com.cobalte.nfticket';
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        // Rediriger vers l'App Store d'Apple
        return 'https://apps.apple.com/fr/app/nf-ticket/id6463010132';
      }
    }
    return 'https://www.nf-ticket.com/app';
  }

  if (!currentEvenement) {
    return <ErrorComponent error="404 Not Found" />;
  }

  if (!currentEvenement && evenementsData.status.fetchEvenementById === 'failed') {
    return (
      <ErrorComponent
        error={evenementsData.error.fetchEvenementById ?? 'An unknown error occurred'}
      />
    );
  }

  if (!currentEvenement) {
    return <MainContainer>Evenement pas trouvé.</MainContainer>;
  }

  if (currentEvenement) {
    const proceedToPayment = (
      currentEvenement: EvenementResponse,
      selectedTickets: TicketInBasketModel[],
      isTicketFreeStatus: { [key: string]: boolean }
    ) => {
      dispatch(setBasketTickets(selectedTickets));
      dispatch(setIdEventToBuy(currentEvenement.id));
      navigate('/payment', { state: { isTicketFreeStatus } });
      //url.navigate('/payment');
    };

    return (
      <MainContainer>
        {currentEvenement &&
        (evenementsData.status.fetchEvenementById === 'loading' ||
          evenementsData.status.fetchEvenementById === 'idle') ? (
          <div className="loader">
            <LoadingComponent />
          </div>
        ) : (
          <Flex
            direction={'column'}
            gap={5}
            width={'80vw'}
            justifySelf={'center'}
            alignSelf={'center'}
          >
            {/* Details Section */}

            <DetailsSection
              currentEvenement={currentEvenement}
              availableTickets={currentEvenement.ticketsCategories}
              proceedToPayment={proceedToPayment}
            />

            {/* Ticket Section */}
            <Flex flexDirection="column" p={5}>
              <Text
                fontSize={isMobile ? 'x-large' : 'xx-large'}
                fontWeight="medium"
                mb={4}
                color={'white'}
              >
                Billets
              </Text>
              <Text fontSize="md" fontWeight="normal" color={'white'}>
                Pour accéder à la billeterie, rendez-vous sur l&apos;application mobile{' '}
                <a style={{ color: '#407bfe', fontWeight: '700' }} href={redirectToAppStore()}>
                  NF-Ticket
                </a>
                .
              </Text>
            </Flex>

            {/* {currentEvenement.ticketsCategories && currentEvenement.ticketsCategories.length > 0 && (
            <TicketSection
              currentEvenement={currentEvenement}
              availableTickets={currentEvenement.ticketsCategories}
              proceedToPayment={proceedToPayment}
            />
          )} */}

            {/* Description Section */}

            <Flex flexDirection="column" p={5}>
              <Text
                fontSize={isMobile ? 'x-large' : 'xx-large'}
                fontWeight="medium"
                mb={4}
                color={'white'}
              >
                Description
              </Text>
              <Text fontSize="md" fontWeight="normal" color={'white'}>
                {currentEvenement.description.toString()}
              </Text>
            </Flex>

            {/* Organisateur Section */}

            {currentEvenement.organizerName && (
              <OrganisateurSection currentEvenement={currentEvenement} />
            )}

            {/* Lieu Section */}

            <LieuSection currentEvenement={currentEvenement} />
          </Flex>
        )}
      </MainContainer>
    );
  }

  return null;
}
