import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Image, Input, Link, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_EVENEMENT_IMG } from '../../config';
import { useApp, useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
import { updateTicketDetails } from '../../slices';
import { RootState, useAppDispatch } from '../../store';
import { findRightTicketInBasket } from '../../utils';
// import { PaymentModal } from './PaymentModal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './checkoutForm';

export function Payment() {
  const userAgent = navigator.userAgent;
  const { isMobile, url } = useApp();
  const dispatch = useAppDispatch();
  const basket = useSelector((state: RootState) => state.basket);
  const { evenementsData, spectateurData } = useFetchData(basket.evenementId || undefined);
  const currentEvenement = basket.evenementId ? evenementsData.byId[basket.evenementId] : undefined;
  const [isTicketSectionOpen, setIsTicketSectionOpen] = useState(true);

  const stripePromise = loadStripe(
    'pk_test_51NNFI6HGy3IzyHoDDghq55QdmrkHjbvOpZxvLEKfJdHRr1fC62s1CMMS8tBMEcC1UnHzsitizVcKT1azVOdmAGi900UXesjPiR'
  );
  function redirectToAppStore() {
    const userAgent = navigator.userAgent;
    if (isMobile) {
      if (userAgent.match(/Android/i)) {
        // Rediriger vers le Google Play Store
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cobalte.nfticket';
      } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
        // Rediriger vers l'App Store d'Apple
        window.location.href = 'https://apps.apple.com/fr/app/nf-ticket/id6463010132';
      }
    }
  }

  const handleTicketInputChange = (
    ticketId: string,
    localIndex: number,
    field: 'nom' | 'prenom',
    value: string
  ) => {
    const [targetTicket, index] = findRightTicketInBasket(
      basket.tickets,
      (t) => t.ticketId === ticketId,

      localIndex + 1
    );

    if (targetTicket !== undefined && index !== undefined) {
      const payload = {
        ticketId,
        index,
        nom: undefined,
        prenom: undefined,
      } as {
        ticketId: string;
        nom: string | undefined;
        prenom: string | undefined;
        index: number;
      };

      payload[field] = value;
      dispatch(updateTicketDetails(payload));
    }
  };

  /*
   * Payment Modal
   */

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardMMYY, setMMYY] = useState<string>('');
  const [cardCVC, setCVC] = useState<string>('');
  const openPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };
  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  /*
   * Handle Payment
   */
  const [errorMessage, setErrorMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const isCardInserted = cardNumber !== '' && cardMMYY !== '' && cardCVC !== '';
    if (!isCardInserted) {
      setErrorMessage('Merci de choisir un moyen de paiement.');
    }

    const areTicketsNamed = basket.tickets.every((ticket) => {
      return ticket.nom && ticket.nom.length >= 2 && ticket.prenom && ticket.prenom.length >= 2;
    });
    if (!areTicketsNamed) {
      setErrorMessage('Merci de renseigner les infromations correctes.');
    }

    setIsValid(isCardInserted);
  }, [
    basket.tickets.map((t) => t.nom),
    basket.tickets.map((t) => t.prenom),
    cardNumber,
    cardMMYY,
    cardCVC,
  ]);

  if (!spectateurData.spectateur) {
    url.navigate('/login');
  }

  if (!basket.evenementId || !currentEvenement || !spectateurData.spectateur) {
    return null;
  }

  const calculateTotal = () => {
    let total = 0;
    basket.tickets.forEach((ticketInBasket) => {
      const currentTicket = currentEvenement.ticketsCategories.find(
        (e) => e.id === ticketInBasket.ticketId
      );

      if (currentTicket) {
        total += currentTicket.prix;
      }
    });

    return Number(total.toFixed(2));
  };

  return (
    <MainContainer>
      <Flex justifyContent={'center'}>
        <Flex
          direction={'column'}
          width={isMobile ? '100%' : '60vw'}
          color={'white'}
          fontWeight={'medium'}
          justifyContent={'center'}
          gap={5}
        >
          <Text fontSize={'xl'} mt={isMobile ? '-4' : '2'} fontWeight={'bold'}>
            Vérifier et payer
          </Text>

          <Box borderBottom="1px solid white" my={-1} />

          {/* Ticket Section */}
          <Flex
            borderRadius={'10px'}
            direction={'column'}
            gap={5}
            bg={'text.lightBlue'}
            py={isMobile ? '2' : '5'}
          >
            <Flex
              gap={isMobile ? 5 : 10}
              pl={isMobile ? '2' : '6'}
              rounded={10}
              onClick={() => setIsTicketSectionOpen(!isTicketSectionOpen)}
            >
              <Box w="100%" h="100%" maxWidth={isMobile ? '300px' : '200px'}>
                <Image
                  src={currentEvenement.banniereImg.toString() || DEFAULT_EVENEMENT_IMG}
                  alt={currentEvenement.banniereImg.toString() || DEFAULT_EVENEMENT_IMG}
                  width={'100%'}
                  height={'120px'}
                  borderRadius={'12px'}
                />
              </Box>
              <Flex mb="35px" flexDirection="column" justifyContent={'flex-end'}>
                <Text fontWeight="semibold" fontSize={isMobile ? 'md' : 'large'}>
                  {currentEvenement.nomEvenement.toString()}
                </Text>
                <Text fontWeight="normal" fontSize={isMobile ? 'md' : 'large'}>
                  {`${Object.values(basket.tickets).length} ${
                    Object.values(basket.tickets).length === 1 ? 'Billet' : 'Billets'
                  }`}
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent="flex-end"
                alignItems={'flex-end'}
                flex={1}
              >
                <Icon
                  as={isTicketSectionOpen ? ChevronUpIcon : ChevronDownIcon}
                  boxSize={isMobile ? '7' : '10'}
                />
              </Flex>
            </Flex>

            {isTicketSectionOpen && (
              <Flex flexDirection="column" gap={5}>
                <Box borderBottom="1px solid white" mx={'8'} my={isMobile ? '-2' : '1'} />
                {evenementsData.byId[basket.evenementId].ticketsCategories.map((ticket) => {
                  const theseTickets = basket.tickets.filter((t) => t.ticketId === ticket.id);
                  const count = theseTickets.length;
                  if (count <= 0) {
                    return null;
                  }

                  return (
                    <Flex
                      key={`billetTotal${ticket.id}`}
                      rounded={10}
                      justifyContent="space-between"
                      ml={10}
                      direction={'column'}
                    >
                      {/* <Box borderBottom="1px solid white" my={2} /> */}

                      <Flex flexDirection="column" width={'70%'}>
                        <Text>{`x${count} ${ticket.nomCategorie.toString()}`}</Text>
                        <Text fontWeight={'bold'} color={'text.lightBlue2'}>{`${
                          ticket.prix * count
                        } €`}</Text>
                      </Flex>

                      <Flex direction={'column'} gap={5}>
                        {theseTickets.map((ticket, index) => {
                          return (
                            <Flex
                              gap={5}
                              width={'100%'}
                              align={'center'}
                              key={`${ticket.ticketId}${index}`}
                            >
                              <Text>{`Billet #${index}`}</Text>
                              <Flex
                                direction={isMobile ? 'column' : 'row'}
                                gap={isMobile ? '2' : '5'}
                                mx={'4'}
                              >
                                <Input
                                  //focusBorderColor="text.lightBlue2"
                                  placeholder={'Nom'}
                                  value={ticket.nom || ''}
                                  onChange={(e) =>
                                    handleTicketInputChange(
                                      ticket.ticketId,
                                      index,
                                      'nom',
                                      e.target.value
                                    )
                                  }
                                />
                                <Input
                                  placeholder={'Prenom'}
                                  value={ticket.prenom || ''}
                                  onChange={(e) =>
                                    handleTicketInputChange(
                                      ticket.ticketId,
                                      index,
                                      'prenom',
                                      e.target.value
                                    )
                                  }
                                />
                              </Flex>
                            </Flex>
                          );
                        })}
                      </Flex>
                    </Flex>
                  );
                })}
                <Text
                  textAlign={isMobile ? 'center' : 'justify'}
                  fontWeight={'bold'}
                  fontSize={'x-small'}
                  color={'text.lightBlue2'}
                >
                  Ton reçu sera disponible par email et ton ticket sur l&apos;application NF-Ticket{' '}
                  <Link href="#" onClick={redirectToAppStore}>
                    <Text as={'span'} color={'white'}>
                      (Par ici)
                    </Text>
                  </Link>
                </Text>
              </Flex>
            )}
          </Flex>

          {/* <Text fontSize={isMobile ? 'md' : 'xl'} fontWeight={'bold'}>
            Payer avec
          </Text> */}

          {/* <Flex direction={'row'} justifyContent={'space-between'}>
            <Flex gap={1}>
              <CreditCardIcon />
              <Text fontSize={isMobile ? 'sm' : 'lg'}>
                {cardNumber ? cardNumber : 'Choisir de carte'}
              </Text>
            </Flex>
            <Text
              fontSize={isMobile ? 'sm' : 'lg'}
              color={'text.lightBlue2'}
              onClick={openPaymentModal}
            >
              Choisir
            </Text>
          </Flex> */}

          {/* <PaymentModal
            isPaymentModalOpen={isPaymentModalOpen}
            closePaymentModal={closePaymentModal}
            cardNumber={cardNumber}
            cardMMYY={cardMMYY}
            cardCVC={cardCVC}
            setCardNumber={setCardNumber}
            setMMYY={setMMYY}
            setCVC={setCVC}
          /> */}

          <Box borderBottom="1px solid white" my={isMobile ? '-1.5' : '2'} />

          <Flex justifyContent={'space-between'}>
            <Text fontSize={isMobile ? 'sm' : 'lg'}>Total</Text>
            <Text fontSize={isMobile ? 'sm' : 'lg'}>{`${calculateTotal()} €`}</Text>
          </Flex>

          {/* <Flex alignItems={'center'} direction={'column'} gap={2}>
            <Button
              width="50vw"
              //onClick={()}
              //isDisabled={!cardNumber || !cardMMYY || !cardCVC || !isValid}
            >
              {`Payer: ${calculateTotal()} €`}
            </Button>

           
          </Flex> 
          */}

          <Elements stripe={stripePromise} options={{}}>
            <CheckoutForm />
          </Elements>
          <Text
            as="u"
            fontWeight={'bold'}
            fontSize={'x-small'}
            color={'text.lightBlue2'}
            textAlign={'center'}
          >
            <Link href={'https://www.nf-ticket.com/condtions-generales-de-vente'} target={'_blank'}>
              En confirmant ta commande tu acceptes les conditions d&apos;utilisation de NF-Ticket
            </Link>
          </Text>
        </Flex>
      </Flex>
    </MainContainer>
  );
}
