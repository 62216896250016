import 'firebase/firestore';
import { AxiosResponse } from 'axios';
import {
  fetchEvenementById,
  fetchOrganisateurs,
  fetchPopularEvenements,
  login,
  refreshToken,
  signup,
} from './services';
import { handleLog } from './utils';

export const FIRESTORE_BASE_URL = process.env.REACT_APP_FIRESTORE_URL || '';

console.log('FIRESTORE_BASE_URL', FIRESTORE_BASE_URL);

export const handleApiResponse = <T>(response: AxiosResponse<T>, endpoint: string): T => {
  const timeStamp = new Date().toISOString();
  handleLog([`[${timeStamp}] Successfully received response from ${endpoint}`]);
  return response.data;
};

export const handleApiError = (error: unknown, endpoint: string) => {
  const timeStamp = new Date().toISOString();
  console.error(`[${timeStamp}] API call to ${endpoint} failed`);

  if (typeof error === 'object' && error !== null && 'response' in error) {
    const axiosError = error as {
      response?: { data?: { error?: { message: string } }; status?: number };
    };
    if (axiosError.response?.data?.error?.message) {
      console.error(
        'API call failed',
        axiosError.response.data.error.message,
        axiosError.response.status
      );
      throw new Error(axiosError.response.data.error.message);
    } else {
      console.error('API call failed', 'No specific error message found');
      throw new Error('An unknown error occurred');
    }
  } else if (error instanceof Error) {
    console.error('API call failed', error.message);
    throw error;
  } else {
    console.error('API call failed', error);
    throw new Error('An unknown error occurred');
  }
};

export const apiService = {
  fetchPopularEvenements: fetchPopularEvenements,
  fetchEvenementById: fetchEvenementById,
  fetchOrganisateurs: fetchOrganisateurs,
  refreshToken: refreshToken,
  login: login,
  signup: signup,
};
