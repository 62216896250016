import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import { useSelector } from 'react-redux';
import { useApp, useFetchData } from '../../hooks';
import { MainContainer } from '../../shared-layout';
import { RootState } from '../../store';
import { FaRegEyeSlash } from 'react-icons/fa6';
import { FaRegEye } from 'react-icons/fa6';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
export function Signup() {
  const { isMobile, url } = useApp();
  const { spectateurData } = useFetchData();
  const basket = useSelector((state: RootState) => state.basket);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [countryCode, setCountryCode] = useState('+33');
  const [sexe, setSexe] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [numTel, setNumTel] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dateNaissance, setSelectedDate] = useState<Date | null>();
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const telPattern = /^\d{9}$/;
  const handleClick = () => setShow(!show);

  registerLocale('fr', fr);
  const CustomDatePickerInput: React.FC<{ value: string; onClick: () => void }> = ({
    value,
    onClick,
  }) => (
    <Input
      placeholder="  Date de naissance"
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      style={{
        borderRadius: '5px', // ou toute autre valeur de bordure arrondie souhaitée
        border: '2px solid text.lightBlue2', // couleur de la bordure rouge
        padding: '5px', // ajustez la marge intérieure selon vos préférences// Ajoutez d'autres styles personnalisés au besoin
      }}
    />
  );
  const years = range(1990, getYear(new Date()) + 0, 1);
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];

  const getErrorMessage = (
    fieldName: string,
    value: string,
    validationCriteria: RegExp,
    required = false
  ) => {
    if (isSubmitted) {
      if (required && !value.trim()) {
        return `${fieldName} requis`;
      }
      if (!validationCriteria.test(value)) {
        switch (fieldName) {
          case 'Numéro de téléphone':
            return 'Numéro de téléphone invalide';
          case 'Nom':
            if (value.trim().length < 4) {
              return 'Le nom doit contenir au moins 4 caractères alphabétiques';
            }
            return "Le nom n'est pas valide";
          case 'Prénom':
            if (value.trim().length < 4) {
              return 'Le prénom doit contenir au moins 4 caractères alphabétiques';
            }
            return "Le prénom n'est pas valide";
          case 'Email':
            return 'Email invalide';
          case 'Mot de passe':
            return 'Le mot de passe doit avoir au moins 8 caractères';
          default:
            return `Le champ ${fieldName} n'est pas valide`;
        }
      }
    }
    return '';
  };
  const url1 = useNavigate();
  const formattedDateNaissance = dateNaissance?.toISOString();
  console.log(formattedDateNaissance);
  const isSexeValid = () => {
    return isSubmitted && !sexe;
  };
  console.log(numTel);
  const handleSignup = async () => {
    setIsSubmitted(true);
    if (isLoading) {
      return;
    }
    if (
      isSubmitted &&
      nom.trim() &&
      prenom.trim() &&
      emailPattern.test(email) &&
      password.length >= 8 &&
      telPattern.test(numTel) &&
      sexe &&
      dateNaissance
    ) {
      console.log(formattedDateNaissance);
      console.log(formattedDateNaissance);
      setIsLoading(true);
      const result = await spectateurData.signup({
        nom,
        prenom,
        email,
        password,
        numTel: `${countryCode}${numTel}`,
        sexe,
        dateNaissance: formattedDateNaissance,
      });
      setIsLoading(false);
      if (!result.success) {
        setErrorMsg(result.message || "Erreur inconnue lors de l'inscription");
      } else {
        setErrorMsg('');

        // if (basket.tickets.length > 0 && basket.evenementId) {
        //   url.navigate('/payment');
        // } else {
        //   url.navigate('/');
        // }
        if (localStorage.getItem('myValue').includes('evenement')) {
          url.navigate(-2);
          console.log(location.pathname, 'je suis event');
          //console.log(location.pathname.startsWith('/login'));
        } else {
          console.log(location.pathname);
          url1('/'); // Vous pouvez ajuster cela en fonction de la structure de vos URL
          console.log(location.pathname, 'je suis dans la condition home');
        }
      }
    } else {
      setErrorMsg('Il faut remplir tous les champs ');
    }
  };

  return (
    <MainContainer>
      <Flex
        width={'900px'}
        justifyContent={'center'}
        alignItems={'center'}
        color={'white'}
        flex={1}
        direction={isMobile ? 'column' : 'row'}
        alignSelf={'center'}
      >
        <Flex
          borderRadius={'3% 0% 0% 3%'}
          width={'60%'}
          height={'110%'}
          bgGradient="linear(to-br, #142025, #005c82 )"
          //bg={'text.lightBlue'}
          color={'white'}
          direction={'column'}
          gap={5}
          p={10}
          justifyContent={'center'}
        >
          <Text fontSize={'xx-large'} fontWeight={'bold'} textAlign={'center'}>
            Content de vous revoir!
          </Text>
          <Text fontSize={'medium'} textAlign={'center'}>
            Pour garder le contact connectez-vous avec vos informations personnelles
          </Text>
          <Button
            backgroundColor={'transparent'}
            border={'1px'}
            borderColor={'white'}
            borderRadius={'20'}
            width={'170px'}
            color={'white'}
            alignSelf={'center'}
            mt={'40px'}
            onClick={() => url.navigate('/login')}
          >
            SE CONNECTER
          </Button>
        </Flex>
        <Flex
          borderRadius={'0% 3% 3% 0%'}
          width={'60%'}
          height={'110%'}
          bgGradient="linear(to-br, #142025, #005c82 )"
          bg={'white'}
          color={'black'}
          direction={'column'}
          gap={5}
          p={10}
          //justifyContent={'center'}
          //alignItems={'center'}
        >
          <Text fontSize={'xx-large'} fontWeight={'bold'} textAlign={'center'}>
            Créer un compte
          </Text>
          <label>Sexe</label>
          <RadioGroup onChange={setSexe} value={sexe}>
            <Stack direction="row" spacing={10}>
              <Radio value="Homme" onClick={(e) => setSexe(sexe)}>
                Homme
              </Radio>
              <Radio value="Femme" onClick={(e) => setSexe(sexe)}>
                Femme
              </Radio>
              <Radio value="NonSpécifié" onClick={(e) => setSexe(sexe)}>
                Non spécifié
              </Radio>
            </Stack>
          </RadioGroup>
          {isSexeValid() && (
            <Text mt={-5} color="red.500" fontSize="12px" textAlign={'start'}>
              Sélectionnez une option pour le champ Sexe
            </Text>
          )}
          <Input
            placeholder="Prenom"
            value={prenom}
            onChange={(e) => setPrenom(e.target.value)}
            type="text"
          />
          {isSubmitted && (
            <Text mt={'-4'} color="red.500" fontSize="12px" textAlign={'start'}>
              {getErrorMessage('Prénom', prenom, /^[a-zA-Z\s]{4,}$/)}
            </Text>
          )}
          <Input
            placeholder="Nom"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            type="text"
          />
          {isSubmitted && (
            <Text mt={'-4'} color="red.500" fontSize="12px" textAlign={'start'}>
              {getErrorMessage('Nom', nom, /^[a-zA-Z\s]{4,}$/)}
            </Text>
          )}
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          {isSubmitted && (
            <Text mt={'-4'} color="red.500" fontSize="12px" textAlign={'start'}>
              {getErrorMessage('Email', email, /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)}
            </Text>
          )}
          <InputGroup>
            <Input
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={show ? 'text' : 'password'}
              //type="password"
            />
            <InputRightElement width="4.5rem">
              <Button backgroundColor={'transparent'} h="1.75rem" size="sm" onClick={handleClick}>
                {show ? (
                  <Icon as={FaRegEye} boxSize={6} />
                ) : (
                  <Icon as={FaRegEyeSlash} boxSize={6} />
                )}
              </Button>
            </InputRightElement>
          </InputGroup>
          {isSubmitted && (
            <Text mt={'-4'} color="red.500" fontSize="12px">
              {getErrorMessage('Mot de passe', password, /^.{8,}$/)}
            </Text>
          )}
          <Flex direction={'row'} gap={2}>
            <Input
              placeholder="(e.g., +33)"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              type="text"
              width="20%"
            />
            <Input
              placeholder="Téléphone"
              value={numTel}
              onChange={(e) => setNumTel(e.target.value)}
              type="tel"
              width="80%"
            />
          </Flex>
          {isSubmitted && (
            <Text mt={-5} color="red.500" fontSize="12px" textAlign={'start'}>
              {getErrorMessage('Numéro de téléphone', numTel, telPattern)}
            </Text>
          )}
          <ReactDatePicker
            selected={dateNaissance}
            onChange={(date) => setSelectedDate(date)}
            dateFormat=" dd MMMM yyyy"
            minDate={new Date('1900-01-01')}
            maxDate={new Date('2023-12-31')}
            showYearDropdown
            showMonthDropdown
            previousYearButtonLabel
            locale={'fr'}
            customInput={
              <CustomDatePickerInput
                value={''}
                onClick={function (): void {
                  console.log(dateNaissance);
                  throw new Error('Function not implemented.');
                }}
              />
            }
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <Box mb={6} display="flex" maxHeight={'10px'} justifyContent="center">
                <Button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  fontSize="20px"
                  colorScheme="transparent"
                  color={'text.lightBlue'}
                >
                  {'<'}
                </Button>
                <Select
                  value={months[getMonth(date)]}
                  onChange={(e) => changeMonth(months.indexOf(e.target.value))}
                  bg="blue.600"
                  colorScheme="teal"
                  color="white"
                  fontSize="12px"
                  padding="5px"
                  cursor="pointer"
                  outline="none"
                  width={'90px'}
                  height={'30'}
                  _hover={{ bg: 'blue.500' }}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                <Select
                  value={getYear(date)}
                  onChange={(e) => changeYear(Number(e.target.value))}
                  //size={'md'}
                  bg="blue.600"
                  color="white"
                  fontSize="12px"
                  padding="5px"
                  cursor="pointer"
                  outline="none"
                  width={'90px'}
                  overflow="auto"
                  height={'30'}
                  _hover={{ bg: 'blue.500' }}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                <Button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  fontSize="20px"
                  colorScheme="transparent"
                  color={'text.lightBlue'}
                >
                  {'>'}
                </Button>
              </Box>
            )}
          />
          <Flex alignItems={'center'} alignSelf={'center'} gap={5}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Button
                backgroundColor={'transparent'}
                border={'1px'}
                borderColor={'text.lightBlue'}
                borderRadius={'20'}
                width={'170px'}
                color={'text.lightBlue'}
                alignSelf={'center'}
                onClick={handleSignup}
              >
                S&apos;INSCRIRE
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </MainContainer>
  );
}
